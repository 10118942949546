// utils/API.js
import axios, { AxiosPromise } from 'axios';
import { getStore } from '../store/store';
import { AUTH_EXPIRE } from '../saga/config/configActionTypes';
import { CLEAR_STORE } from '../saga/login/loginActionTypes';

const API = (
  methodType: any,
  endpoint: string,
  payload?: any,
  authRequire?: boolean,
  accessTokenRequire?: boolean
): AxiosPromise => {
  const headers: any = {
    'Content-Type': 'application/json',
  };
  if (authRequire) {
    const store = getStore().getState();
    const authToken = store.authentication?.authData?.authToken;
    if (authToken) headers['auth-token'] = authToken;
  }
  if (accessTokenRequire) {
    const store = getStore().getState();
    const accessToken = store.authentication?.authData?.jwtDetails?.accessToken;
    if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return axios({
    method: methodType,
    url: endpoint,
    data: payload,
    headers,
  });
};

axios.interceptors.request.use((request) => {
  return request;
});
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const store = getStore();
    if (error.response.status === 401) {
      store.dispatch({ type: AUTH_EXPIRE, response: error.response });
      store.dispatch({ type: CLEAR_STORE });
    }
    throw error;
  }
);
export default API;
