import { getStore } from '../store/store';
import { AuthTypes } from '../types/reducerTypes';
export const config = {
  store() {
    return getStore()?.getState();
  },
  CLIENT: 'US',
  ZEROKEYCHAR: 48,
  ONEKEYCHAR: 49,
  MAX_PHONE_LENGTH: 14,
  AUTH_TOKEN() {
    return this.store()?.authentication?.authData?.authToken;
  },
  user() {
    return this.store()?.authentication?.authData;
  },
  EVENT_HEADER: 'CSR',
  getPubnubConfig() {
    const store = this.store();
    const subscribeChannels = [
      store?.authentication?.authData?.channel,
      store?.authentication?.authData?.chatChannel,
    ];
    return {
      keySet: {
        subscribeKey: store?.authentication?.authData?.configs?.config[
          'pubnub.subscription.key'
        ]
          ? store.authentication.authData.configs.config[
              'pubnub.subscription.key'
            ]
          : '',
      },
      channel: subscribeChannels,
    };
  },
  isVehicleManagementEnabled() {
    const store = this.store();
    const providerPreference =
      store?.authentication?.authData?.configs?.providerPreference;
    return !!(
      providerPreference &&
      (typeof providerPreference.vehicleManagementEnabled === 'undefined' ||
        providerPreference.vehicleManagementEnabled)
    );
  },
  isBackgroundCheckEnabled() {
    const store = this.store();
    const businessInfo = store?.fleet?.businessInfo;
    return !!businessInfo.backgroundCheckEnabled;
  },
  BUILDVERSION: '4.1.0',
  SENTRY_DSN:
    'https://6c554dd07db94426abd00ffa75310f0c@o222561.ingest.sentry.io/6539588',
  // -------------------------------------
  TABLEAU: 'https://online.tableau.com',
};
