import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { CustomModal, Button } from '../../urgently-component';
import '../vehicles/vehicles.scss';
import { VehicleDTO, Categories } from '../../types/reducerTypes';

interface Props {
  show: boolean;
  handleClose: () => void;
  vehicle: VehicleDTO;
  index: number;
  categories: Categories[];
  editVehicle: (vehicle: VehicleDTO, idx: number) => void;
  deleteVehicle: (vehicle: VehicleDTO, idx: number) => void;
}

export const ViewVehicleModal: React.FC<Props> = (props: Props) => {
  const { index, vehicle, categories, editVehicle, deleteVehicle } = props;
  return (
    <CustomModal
      show={props.show}
      closeModal={props.handleClose}
      title="Vehicle"
      actionButtons={
        <>
          <Button
            variant="basic"
            data-testid="btn-edit"
            clx="button_secondary black btn_gap"
            onClick={editVehicle.bind(this, vehicle, index)}
          >
            Edit
          </Button>
          <Button
            variant="basic"
            data-testid="btn-delete"
            clx="button_danger"
            disabled={false}
            onClick={deleteVehicle.bind(this, vehicle, index)}
          >
            Delete
          </Button>
        </>
      }
    >
      <Form className="custom_form">
        <Row>
          <Col xs={4}>
            <div className="profile_img mt10">
              <div className="img_box edit">
                {vehicle?.truckInfo?.picture ? (
                  <div className="tab_mask">
                    <img
                      className="icon"
                      alt="img"
                      src={vehicle.truckInfo.picture}
                    />
                  </div>
                ) : (
                  <div className="user_mask_view">
                    {vehicle?.truckInfo?.name?.charAt(0) || ''}
                  </div>
                )}
              </div>
            </div>

            <div
              className={`driver_shift_text ${
                vehicle?.truckInfo?.status === 1 ? 'on_shift' : ''
              }`}
            >
              {vehicle?.truckInfo?.status === 0
                ? 'Off Shift'
                : vehicle?.truckInfo?.status === 1
                ? 'On Shift'
                : 'Busy'}
            </div>
          </Col>

          <Col xs={8}>
            <Form.Group>
              <Form.Label>NAME</Form.Label>
              <p className="form_control_data cap mb10">
                {vehicle?.truckInfo?.name || 'No Vehicle name entered'}
              </p>

              <Form.Label>TYPE</Form.Label>
              <p className="form_control_data mb10">
                {vehicle?.truckInfo?.truckType}
              </p>

              <Form.Label htmlFor="name">LICENSE PLATE</Form.Label>
              <p className="form_control_data cap mb10">
                {vehicle?.truckInfo?.licensePlate || ''}
              </p>

              <Form.Label htmlFor="name">EXTERNAL ID</Form.Label>
              <p className="form_control_data mb10">
                {vehicle?.truckInfo?.externalId || 'N/A'}
              </p>

              <Form.Label htmlFor="name">SERVICES</Form.Label>
              <ul className="service_list">
                {vehicle?.truckInfo?.services?.map((service: number) => {
                  for (const i in categories) {
                    if (categories[i].id === service) {
                      return (
                        <li key={categories[i].id}>{categories[i].value}</li>
                      );
                    }
                  }
                })}
              </ul>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default ViewVehicleModal;
