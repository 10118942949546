import { put, select, takeLatest } from 'redux-saga/effects';
import {
  AUTH_EXPIRE,
  ERROR_OCCUR,
  ERROR_REMOVE,
  HIDE_LOADING,
  SHOW_LOADING,
} from '../config/configActionTypes';
import { getAuthData } from './loginSelectors';
import API from '../../utils/API';
import { HTTP_GET, HTTP_POST, LOGIN_NEW } from '../../constants/API';
import { LOGIN_REQUEST, LOGIN_SUCCESS } from './loginActionTypes';

function* verifyLogin(payload: any): any {
  try {
    const response = yield API(HTTP_POST, LOGIN_NEW, payload.param);
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      yield put({ type: LOGIN_SUCCESS, response: response.data });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}

export function* authExpire(error: any): any {
  yield put({ type: ERROR_OCCUR, response: error?.response?.data });
}

function* loginWatcher(): any {
  yield takeLatest(LOGIN_REQUEST, verifyLogin);
  yield takeLatest(AUTH_EXPIRE, authExpire);
}

export default loginWatcher;
