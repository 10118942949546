export const Events = {
  ON_FLEET_ASSETS_DRIVER_SCREEN: {
    id: 80118,
    name: 'ON_FLEET_ASSETS_DRIVER_SCREEN',
    Actions: {
      BG_CHECK_REQUESTED: {
        id: 90129,
        name: 'BG_CHECK_REQUESTED',
      },
      BG_CHECK_REQUEST_FAILED: {
        id: 90130,
        name: 'BG_CHECK_REQUEST_FAILED',
      },
      DRIVER_START_SHIFT: {
        id: 90131,
        name: 'DRIVER_START_SHIFT',
      },
      DRIVER_END_SHIFT: {
        id: 90132,
        name: 'DRIVER_END_SHIFT',
      },
    },
    Pages: {
      ON_FLEET_ASSETS_DRIVER_ADD_MODAL: {
        id: 80119,
        name: 'ON_FLEET_ASSETS_DRIVER_ADD_MODAL',
        Actions: {
          DRIVER_ADDED: {
            id: 90133,
            name: 'DRIVER_ADDED',
          },
        },
      },
      ON_FLEET_ASSETS_DRIVER_VIEW_MODAL: {
        id: 80120,
        name: 'ON_FLEET_ASSETS_DRIVER_VIEW_MODAL',
        Actions: {
          ACTIVATION_CODE_REGENERATED: {
            id: 90134,
            name: 'ACTIVATION_CODE_REGENERATED',
          },
          TEXT_LINK_SENT: {
            id: 90135,
            name: 'TEXT_LINK_SENT',
          },
          DRIVER_DELETED: {
            id: 90136,
            name: 'DRIVER_DELETED',
          },
        },
      },
      ON_FLEET_ASSETS_DRIVER_EDIT_MODAL: {
        id: 80121,
        name: 'ON_FLEET_ASSETS_DRIVER_EDIT_MODAL',
        Actions: {
          DRIVER_EDITED: {
            id: 90137,
            name: 'DRIVER_EDITED',
          },
        },
      },
      ON_ASSIGN_VEHICLE_MODAL: {
        id: 80122,
        name: 'ON_ASSIGN_VEHICLE_MODAL',
        Actions: {
          VEHICLE_ASSIGNED_TO_DRIVER: {
            id: 90138,
            name: 'VEHICLE_ASSIGNED_TO_DRIVER',
          },
        },
      },
    },
  },
  ON_FLEET_ASSETS_VEHICLE_SCREEN: {
    id: 80123,
    name: 'ON_FLEET_ASSETS_VEHICLE_SCREEN',
    Actions: {
      VEHICLE_START_SHIFT: {
        id: 90139,
        name: 'VEHICLE_START_SHIFT',
      },
      VEHICLE_END_SHIFT: {
        id: 90140,
        name: 'VEHICLE_END_SHIFT',
      },
    },
    Pages: {
      ON_FLEET_ASSETS_VEHICLE_ADD_MODAL: {
        id: 80124,
        name: 'ON_FLEET_ASSETS_VEHICLE_ADD_MODAL',
        Actions: {
          VEHICLE_ADDED: {
            id: 90141,
            name: 'VEHICLE_ADDED',
          },
        },
      },
      ON_FLEET_ASSETS_VEHICLE_VIEW_MODAL: {
        id: 80125,
        name: 'ON_FLEET_ASSETS_VEHICLE_VIEW_MODAL',
        Actions: {
          VEHICLE_DELETED: {
            id: 90142,
            name: 'VEHICLE_DELETED',
          },
        },
      },
      ON_FLEET_ASSETS_VEHICLE_EDIT_MODAL: {
        id: 80126,
        name: 'ON_FLEET_ASSETS_VEHICLE_EDIT_MODAL',
        Actions: {
          VEHICLE_EDITED: {
            id: 90143,
            name: 'VEHICLE_EDITED',
          },
        },
      },
      ON_ASSIGN_DRIVER_MODAL: {
        id: 80127,
        name: 'ON_ASSIGN_DRIVER_MODAL',
        Actions: {
          DRIVER_ASSIGNED_TO_VEHICLE: {
            id: 90144,
            name: 'DRIVER_ASSIGNED_TO_VEHICLE',
          },
        },
      },
    },
  },
};
