import { config } from './config';

export function isValidEmail(email: string): boolean {
  const re = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  let isValid = false;
  if (re.test(email)) {
    isValid = true;
  }
  return isValid;
}

export function formatFullPhoneNumber(phoneNumber: string): string {
  let formattedPhone = '',
    isMobile,
    isLandline;
  if (!phoneNumber) return '';
  phoneNumber.split('').forEach((digit: string) => {
    formattedPhone += digit;
    if (
      formattedPhone.substr(0, 2) === '04' ||
      formattedPhone.substr(0, 2) === '05'
    ) {
      isMobile = true;
      isLandline = false;
    } else if (
      formattedPhone.substr(0, 3) === '(04' ||
      formattedPhone.substr(0, 3) === '(05'
    ) {
      isMobile = true;
      isLandline = false;
    } else {
      isLandline = true;
      isMobile = false;
    }
    formattedPhone = phoneFormatter(
      formattedPhone,
      config.CLIENT,
      isMobile,
      isLandline
    );
  });

  return formattedPhone;
}

export function phoneFormatter(
  tel: string,
  client = 'US',
  isMobile?: boolean,
  isLandline?: boolean
): any {
  if (client === 'US' || client === 'CA' || !client) {
    if (tel) {
      if (tel.length === 1) {
        return '(' + tel;
      } else if (tel.length === 4) {
        return tel + ') ';
      } else if (tel.length === 5) {
        if (tel.charAt(4) !== ')') {
          return tel.substr(0, 4) + ') ' + tel.charAt(4);
        } else {
          return tel + ' ';
        }
      } else if (tel.length === 6) {
        if (tel.charAt(5) !== ' ') {
          return tel.substr(0, 5) + ' ' + tel.charAt(5);
        } else {
          return tel;
        }
      } else if (tel.length === 9) {
        return tel + '-';
      } else if (tel.length === 10) {
        if (tel.charAt(9) !== '-') {
          return tel.substr(0, 9) + '-' + tel.charAt(9);
        } else {
          return tel;
        }
      } else {
        return tel;
      }
    } else {
      return tel;
    }
  } else if (client === 'AU') {
    if (tel) {
      if (tel.length === 4) {
        return tel + '\u00A0';
      } else if (tel.length === 8) {
        return tel + '\u00A0';
      } else if (tel.length === 10) {
        return (
          tel.substr(0, 4) + ' ' + tel.substr(4, 3) + ' ' + tel.substr(7, 3)
        );
      } else {
        return tel;
      }
    }
  } else if (client === 'GB') {
    if (tel) {
      if (tel.length === 4) {
        return tel + '\u00A0';
      } else if (tel.length === 8) {
        return tel + '\u00A0';
      } else if (tel.length === 10) {
        return (
          tel.substr(0, 4) + ' ' + tel.substr(4, 3) + ' ' + tel.substr(7, 3)
        );
      } else {
        return tel;
      }
    }
  }
}

export function validatePhone(event: any, customerPhone: string): any {
  const key = event.keyCode || event.charCode;
  if (
    (key === config.ZEROKEYCHAR && !customerPhone) ||
    (key === config.ONEKEYCHAR && !customerPhone) ||
    (key === config.ZEROKEYCHAR && customerPhone === '(') ||
    (key === config.ONEKEYCHAR && customerPhone === '(')
  ) {
    event.preventDefault();
  } else if ((key >= 48 && key <= 57) || key === 8) {
    return;
  } else {
    event.preventDefault();
  }
}

export function createMarkup(content: string): any {
  return { __html: content };
}

export function bgStatusList(): any {
  // --- States manage by Internal System ---
  // When ULY 'sends the text message' to the driver,
  const REQUEST_SENT = 'Request Sent'; // (Grey)

  // When the Driver 'has completed' the ULY background check form,
  const REQUEST_COMPLETED = 'Request Completed'; // (Grey)

  //  --- States manage by Checkr ---
  // When Checkr send the background 'check invitation'
  const INVITATION_SENT = 'Invitation Sent'; // (Gold)

  // If the Checkr background check 'invitation expires'
  const INVITATION_EXPIRED = 'Invitation Expired'; // (Grey)

  // If the Checkr background check 'invitation completed'
  const INVITATION_COMPLETED = 'Invitation Completed'; // #364347

  // If the Checkr background check 'is processing'
  const PENDING = 'Pending...'; // (Grey)

  // When Checkr completes the background check,
  const CLEAR = 'Clear'; // (GREEN)
  const CONSIDER = 'Consider'; // (RED)
  const DISPUTED = 'Disputed'; // (Grey), or
  const SUSPENDED = 'Suspended'; // (Grey)

  // When checkr return consider. Adjudication status.
  const UNDER_REVIEW = 'Under Review';
  const PASSED = 'Passed';
  const FAILED = 'Failed';

  return [
    { key: 'text sent', value: REQUEST_SENT, color: '#B2C6CD', bgReqBtn: true }, // grey
    {
      key: 'candidate created',
      value: REQUEST_COMPLETED,
      color: '#B2C6CD',
      bgReqBtn: false,
    }, // grey

    {
      key: 'invitation sent',
      value: INVITATION_SENT,
      color: '#F3BC65',
      bgReqBtn: false,
    }, // gold
    {
      key: 'expired',
      value: INVITATION_EXPIRED,
      color: '#B2C6CD',
      bgReqBtn: false,
    }, // grey
    {
      key: 'completed',
      value: INVITATION_COMPLETED,
      color: '#364347',
      bgReqBtn: false,
    }, // 364347

    { key: 'pending', value: PENDING, color: '#9daeb4', bgReqBtn: false }, // grey - #B2C6CD
    { key: 'clear', value: CLEAR, color: '#3EB5AC', bgReqBtn: false }, // green
    { key: 'consider', value: CONSIDER, color: '#F84545', bgReqBtn: false }, // red
    { key: 'disputed', value: DISPUTED, color: '#B2C6CD', bgReqBtn: false }, // grey
    { key: 'suspended', value: SUSPENDED, color: '#B2C6CD', bgReqBtn: false }, // grey

    {
      key: 'pre adverse action',
      value: UNDER_REVIEW,
      color: '#F3BC65',
      bgReqBtn: false,
    }, // gold
    { key: 'engaged', value: PASSED, color: '#3EB5AC', bgReqBtn: false }, // green
    {
      key: 'Pro adverse action',
      value: FAILED,
      color: '#F84545',
      bgReqBtn: false,
    }, // red
  ];
}
