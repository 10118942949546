import React, { useState } from 'react';
import './vehicles.scss';
import { Col, Container, Row } from 'react-bootstrap';
import {
  Table,
  TableHeader,
  TableItem,
  Button,
} from '../../urgently-component';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getFleetData } from '../../saga/fleet/fleetSelector';
import { getAuthData } from '../../saga/login/loginSelectors';
import * as fleetAction from '../../saga/fleet/fleetActionTypes';
import AddEditModal from '../addEditModal/addEditModal';
import DeleteScreen from './deleteScreen';
import {
  AddEditModalDTO,
  AuthTypes,
  DriverDTO,
  DriverInfo,
  Location,
  TruckInfo,
  VehicleDTO,
} from '../../types/reducerTypes';
import moment from 'moment';
import ViewVehicleModal from './viewVehicleModal';
import AssignDriverVehicleModal from '../technicians/assignDriverVehicleModal';
import { AppEvents } from '../../constants/analytics';
import { Events } from '../../constants/appEvents';

interface Props {}

export const VehicleListing: React.FC<Props> = (props: Props) => {
  const [vehicleDetails, setVehicleDetails] = React.useState({
    data: [],
  });
  const [showDeleteScreen, setDeleteScreen] = React.useState({
    show: false,
    name: '',
    data: {} as VehicleDTO,
    idx: -1,
  });
  const addEditModalData: AddEditModalDTO = {
    index: -1,
    firstName: '',
    name: '',
    licencePlate: '',
    truckType: '',
    truckTypeId: -1,
    lastName: '',
    externalId: '',
    phoneNumber: '',
    picture: '',
    file: {},
    email: '',
  };
  const [showAddEditModal, setAddEditModal] = React.useState({
    modalType: '',
    operation: '',
    show: false,
    data: addEditModalData,
  });

  const [showViewModal, setViewModal] = React.useState({
    show: false,
    vehicle: {} as VehicleDTO,
    idx: -1,
  });

  const [showAssignDriverModal, setAssignDriverModal] = React.useState({
    show: false,
    assignType: '',
    vehicle: {} as VehicleDTO,
    idx: -1,
  });

  const fleetData = useSelector(getFleetData, shallowEqual);
  const providerData: AuthTypes = useSelector(getAuthData, shallowEqual);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setVehicleData();
  }, [fleetData.vehicleList]);

  const RenderHeader = ({ tableName }: { tableName: any }): any => (
    <TableHeader
      className="table_header"
      cells={[
        '',
        'Name',
        'Type',
        'Assigned Driver',
        'External Id',
        'Licence Plate',
        '',
      ]}
    />
  );

  const NoDataFound = (): any => (
    <tr>
      <td colSpan={6} className="p-3 no_data_found" title="no-data-found">
        No Vehicles Found
      </td>
    </tr>
  );

  const RenderItem = ({
    item,
    keyId,
    tableName,
  }: {
    item: any;
    keyId: any;
    tableName: any;
  }): any => (
    <TableItem
      isChoosable={false}
      isChosen={false}
      tableName={tableName}
      // addEditModal={openAddEditModal}
      clx="actions_hover"
      data={[
        {
          type: 'profile',
          value: item[0],
          initial: `${item[1].truckInitial || ''}`,
        }, // profilePic
        {
          type: 'customTableData',
          value: item[1].renderItem,
        }, // name
        item[2], // truckType
        {
          type: 'customTableData',
          value: item[3],
        }, // assignedDriverName
        item[4], // externalId
        item[5], // licensePlate
        {
          type: 'customTableData',
          value: item[6], // actionButtons
        },
      ]}
      id={keyId}
      isDelete={fleetData.driverList[keyId]?.status === 0}
    />
  );

  const setVehicleData = (): any => {
    const vehicleArr: any = [];
    for (let i = 0; i < fleetData.vehicleList?.length; i++) {
      const tmp: any = [];
      const vehicleDTO: VehicleDTO = fleetData.vehicleList[i];
      const truckInfo: TruckInfo = vehicleDTO?.truckInfo;
      const driverInfo: DriverInfo = vehicleDTO?.driverInfo;
      const location: Location = vehicleDTO?.location;
      // 0
      tmp.push(truckInfo?.picture);
      // 1
      tmp.push({
        truckInitial: vehicleDTO?.truckInfo?.truckInitial,
        renderItem: (
          <>
            <span
              className="user_link cap"
              onClick={() => {
                setViewModal({
                  show: true,
                  vehicle: vehicleDTO,
                  idx: i,
                });
                AppEvents.trackPage(
                  Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
                    .ON_FLEET_ASSETS_VEHICLE_VIEW_MODAL.id,
                  Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
                    .ON_FLEET_ASSETS_VEHICLE_VIEW_MODAL.name,
                  null,
                  {
                    uid: truckInfo.uid,
                    name: truckInfo.name,
                  }
                );
              }}
            >
              {truckInfo?.name || 'No Vehicle name entered'}
            </span>
            <br />
            <div
              className={`shift_label ${
                truckInfo.status === 0 ? 'offshift' : ''
              }`}
            >
              {truckInfo.status === 0
                ? 'Off Shift'
                : truckInfo.status === 1
                ? 'On Shift'
                : 'Busy'}
            </div>
          </>
        ),
      });
      // 2
      tmp.push(truckInfo?.truckType);
      // 3
      tmp.push(
        <div className="cap">
          <div>
            {driverInfo?.firstName || driverInfo?.lastName
              ? `${driverInfo?.firstName || ''} ${driverInfo?.lastName || ''}`
              : 'None'}
          </div>
          {(driverInfo?.firstName || driverInfo?.lastName) && location && (
            <div className="fsize14">
              {location.street && location.city && location.state
                ? `${location.street}, ${location.city}, ${
                    location.state
                  } - ${moment(location.lastVehicleLocationUpdatedAt).format(
                    'h:mm A'
                  )}`
                : ''}
            </div>
          )}
          {!driverInfo ||
            (!driverInfo.firstName && !driverInfo.lastName && (
              <span className="vehicle_assigned">None</span>
            ))}
        </div>
      );
      // 4
      tmp.push(truckInfo?.externalId || 'N/A');
      // 5
      tmp.push(truckInfo?.licensePlate || 'N/A');
      // 6
      tmp.push(
        <div className="actions">
          {truckInfo.status !== undefined &&
            (truckInfo.status === 0 || truckInfo.status === 1) && (
              <a
                className="actions_link"
                onClick={() => {
                  changeShiftStatusForVehicle(vehicleDTO);
                }}
              >
                {truckInfo.status === 0 ? 'Start Shift' : 'End Shift'}
              </a>
            )}
          <a
            className="actions_link"
            data-testid={`link-driver-${i + 1}`}
            onClick={() => {
              openAssignDriverModal(vehicleDTO, i);
            }}
          >
            {driverInfo?.firstName || driverInfo?.lastName
              ? 'Reassign Driver'
              : 'Assign Driver'}
          </a>
        </div>
      );
      vehicleArr.push(tmp);
    }
    setVehicleDetails({
      data: vehicleArr,
    });
    return vehicleDetails;
  };

  const changeShiftStatusForVehicle = (vehicle: VehicleDTO): any => {
    if (vehicle.truckInfo?.status === 0) {
      // 'Start Shift'
      dispatch({
        type: fleetAction.CHANGE_VEHICLE_SHIFT,
        request: fleetAction.START_SHIFT,
        data: {
          truckUid: vehicle.truckInfo.uid,
          vehicleName: vehicle.truckInfo.name,
          payload: {
            location: {
              street: '',
              city: '',
              state: '',
              zip: '',
              latitude: '0.00',
              longitude: '0.00',
            },
            truckInfo: {
              uid: vehicle.truckInfo.uid,
              truckTypeId: vehicle.truckInfo.truckTypeId,
            },
            driverInfo: {},
          },
        },
      });
    } else {
      // 'End Shift'
      dispatch({
        type: fleetAction.CHANGE_VEHICLE_SHIFT,
        request: fleetAction.END_SHIFT,
        data: {
          payload: { truckInfo: { id: vehicle.truckInfo.truckTypeId } },
          truckUid: vehicle.truckInfo.uid,
          vehicleName: vehicle.truckInfo.name,
        },
      });
    }
  };

  const openAssignDriverModal = (vehicle: VehicleDTO, index: number): any => {
    setAssignDriverModal({
      show: true,
      vehicle,
      assignType: 'DRIVER',
      idx: index,
    });
    AppEvents.trackPage(
      Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages.ON_ASSIGN_DRIVER_MODAL.id,
      Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages.ON_ASSIGN_DRIVER_MODAL.name
    );
  };

  const linkDriverVehicle = (data: any): void => {
    dispatch({
      type: fleetAction.LINK_DRIVER_VEHICLE,
      data,
    });
    setAssignDriverModal({
      show: false,
      assignType: '',
      vehicle: {} as VehicleDTO,
      idx: -1,
    });
  };

  const deleteAction = (idx: number): any => {
    dispatch({
      type: fleetAction.DELETE_VEHICLE,
      data: {
        idx,
        uid: showDeleteScreen.data.truckInfo.uid,
        name: showDeleteScreen.data.truckInfo.name,
      },
    });

    setDeleteScreen({
      show: false,
      name: '',
      data: {} as VehicleDTO,
      idx: -1,
    });

    setViewModal({
      show: false,
      vehicle: {} as VehicleDTO,
      idx: -1,
    });
  };

  const saveChanges = (dataObj: any): any => {
    const data: any = {
      truckInfo: {
        externalId: dataObj.externalId,
        licensePlate: dataObj.licencePlate,
        name: dataObj.name,
        truckTypeId: dataObj.truckTypeId,
        // picture: dataObj.picture,
        file: dataObj.file,
        services: dataObj.services,
      },
    };
    if (showAddEditModal.operation === 'add') {
      data.truckInfo.providerId = providerData.providerId;
      dispatch({ type: fleetAction.ADD_VEHICLE, data });
    } else {
      data.truckInfo.uid =
        fleetData.vehicleList[showAddEditModal.data.index].truckInfo.uid;
      dispatch({ type: fleetAction.UPDATE_VEHICLE, data });
    }

    setAddEditModal({
      ...showAddEditModal,
      data: addEditModalData,
      show: false,
    });

    setViewModal({
      show: false,
      vehicle: {} as VehicleDTO,
      idx: -1,
    });
  };

  const openAddEditModal = (
    modalType: string,
    operation: string,
    show: boolean,
    data?: any
  ): any => {
    setAddEditModal({
      ...showAddEditModal,
      modalType,
      operation,
      show,
      data,
    });
  };

  const openEditVehicleModal = (vehicle: VehicleDTO, index: number): void => {
    openAddEditModal('vehicle', 'edit', true, {
      picture: vehicle?.truckInfo?.picture,
      truckTypeId: vehicle?.truckInfo?.truckTypeId,
      truckType: vehicle?.truckInfo?.truckType,
      name: vehicle?.truckInfo?.name,
      licencePlate: vehicle?.truckInfo?.licensePlate,
      externalId: vehicle?.truckInfo?.externalId,
      services: vehicle?.truckInfo?.services,
      index: index,
      file: {},
    });
    AppEvents.trackPage(
      Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
        .ON_FLEET_ASSETS_VEHICLE_EDIT_MODAL.id,
      Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
        .ON_FLEET_ASSETS_VEHICLE_EDIT_MODAL.name,
      null,
      { uid: vehicle.truckInfo.uid, name: vehicle.truckInfo.name }
    );
  };

  const confirmDeleteVehicle = (vehicle: VehicleDTO, idx: number): void => {
    setDeleteScreen({
      show: true,
      name: vehicle?.truckInfo?.name,
      data: vehicle,
      idx,
    });
  };

  return (
    <Container fluid>
      <Row>
        <div className="tab_wrap pl15 pr15 pt25">
          <Row>
            <Col sm={8}>
              <h3 className="sub_title">VEHICLES</h3>
              <p className="sub_title_info">Add and manage your vehicles.</p>
            </Col>
            <Col sm={4}>
              <Button
                variant="basic"
                data-testid="add-vehicle"
                clx="button_secondary black float-right"
                onClick={() => {
                  setAddEditModal({
                    modalType: 'vehicle',
                    operation: 'add',
                    show: true,
                    data: addEditModalData,
                  });
                  AppEvents.trackPage(
                    Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
                      .ON_FLEET_ASSETS_VEHICLE_ADD_MODAL.id,
                    Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
                      .ON_FLEET_ASSETS_VEHICLE_ADD_MODAL.name
                  );
                }}
              >
                Add Vehicle
              </Button>
            </Col>
          </Row>

          <div className="settings_table showScroll">
            <Table
              tableName="Vehicle Table"
              clx="round_border"
              data={vehicleDetails.data}
              renderHeader={RenderHeader}
              renderItem={RenderItem}
              noDataFound={NoDataFound}
            />
          </div>
        </div>
      </Row>

      <AddEditModal
        show={showAddEditModal.show}
        handleClose={() =>
          setAddEditModal({
            ...showAddEditModal,
            data: addEditModalData,
            show: false,
          })
        }
        modalType={showAddEditModal.modalType}
        operation={showAddEditModal.operation}
        data={showAddEditModal.data}
        saveChanges={saveChanges}
      />

      <DeleteScreen
        show={showDeleteScreen.show}
        name={showDeleteScreen.name}
        index={showDeleteScreen.idx}
        handleClose={() =>
          setDeleteScreen({
            name: '',
            show: false,
            data: {} as VehicleDTO,
            idx: -1,
          })
        }
        deleteAction={deleteAction}
      />

      <ViewVehicleModal
        show={showViewModal.show}
        vehicle={showViewModal.vehicle}
        index={showViewModal.idx}
        categories={[...fleetData.categories]}
        handleClose={() => {
          setViewModal({
            show: false,
            vehicle: {} as VehicleDTO,
            idx: -1,
          });
        }}
        editVehicle={openEditVehicleModal}
        deleteVehicle={confirmDeleteVehicle}
      />

      <AssignDriverVehicleModal
        show={showAssignDriverModal.show}
        driverOrVehicle={showAssignDriverModal.vehicle}
        index={showAssignDriverModal.idx}
        assignType={showAssignDriverModal.assignType}
        handleClose={() => {
          setAssignDriverModal({
            show: false,
            assignType: '',
            vehicle: {} as VehicleDTO,
            idx: -1,
          });
        }}
        linkDriverVehicle={linkDriverVehicle}
      />
    </Container>
  );
};

export default VehicleListing;
