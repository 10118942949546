import React from 'react';
import moment from 'moment';
import { Toggler } from '../toggler/toggler';
import './tableItem.scss';
import ICON_EDIT_WHITE_IMG from '../../stories/assets/ic_pencil_white.svg';
import ICON_RED_CANCEL_IMG from '../../stories/assets/ic_Red_Cancel.svg';

export interface Props {
  isChoosable: boolean;
  isChosen: boolean;
  data: any[];
  id: any;
  itemDetails?: any;
  toggleTableItem?: (id: number) => void;
  tableName?: string;
  addEditModal?: (
    modalType: string,
    operation: string,
    show: boolean,
    data: any
  ) => void;
  isDelete?: boolean;
  deleteScreen?: (modalType: string, name: string, index: number) => void;
  financeDetail?: (id: number) => void;
  clx?: string;
}

export const TableItem: React.FC<Props> = (props) => {
  const {
    isChosen,
    isChoosable,
    data,
    id,
    toggleTableItem,
    itemDetails: ItemDetails,
    deleteScreen,
    addEditModal,
    isDelete,
    financeDetail,
    clx,
  } = props;

  const toggleShowJobInfo = (): void | null => {
    if (toggleTableItem) {
      toggleTableItem(id);
    } else if (financeDetail) {
      financeDetail(props.data[0]);
    } else {
      return null;
    }
  };

  const openEditModal = (prntIdx: number, isDelete?: string): any => {
    if (isDelete) {
      if (props.tableName === 'Driver Table' && deleteScreen) {
        deleteScreen('driver', props.data[1] + ' ' + props.data[2], prntIdx);
      } else {
        if (deleteScreen) {
          deleteScreen('vehicle', props.data[2], prntIdx);
        }
      }
    } else {
      if (props.tableName === 'Driver Table' && addEditModal) {
        addEditModal('driver', 'edit', true, {
          picture: props.data[0].value,
          firstName: props.data[1],
          lastName: props.data[2],
          externalId: props.data[3],
          phoneNumber: props.data[4],
          index: prntIdx,
          file: {},
        });
      } else {
        if (addEditModal) {
          addEditModal('vehicle', 'edit', true, {
            picture: props.data[0],
            truckType: props.data[1],
            name: props.data[2],
            externalId: props.data[3],
            licencePlate: props.data[4],
            index: prntIdx,
            file: {},
          });
        }
      }
    }
  };

  return (
    <>
      <tr
        className={`${isChosen ? 'chosenRow' : 'ordinaryRow'} ${clx ?? ''}`}
        onClick={toggleShowJobInfo}
      >
        {data.map((item: any, idx: number) => {
          const placeholder = <td key={idx} className="p-3"></td>;
          if (item === null || item === '') return placeholder;
          let content: any;
          if (typeof item === 'string' || typeof item === 'number') {
            content = (
              <td key={idx} className="p-3">
                {item}
              </td>
            );
          } else if (typeof item === 'object') {
            if (item.type === 'toggler' && isChoosable) {
              content = <Toggler key={idx} isChosen={isChosen} />;
            } else if (item.type === 'date') {
              content = (
                <td key={idx} className="p-3">
                  {moment(item.value).format(item.format)}
                </td>
              );
            } else if (item.type === 'profile') {
              if (item.value) {
                content = (
                  <td key={idx}>
                    <div className="tab_mask">
                      <img className="tab_img" alt="img" src={item.value} />
                    </div>
                  </td>
                );
              } else if (!item.value) {
                content = (
                  <td key={idx}>
                    <div className="thumb_initial">
                      {item.initial !== undefined && item.initial !== null
                        ? item.initial
                        : `${data[1][0] || ''}${data[2][0] || ''}`}
                    </div>
                  </td>
                );
              }
            } else if (item.type === 'operation') {
              content = (
                <td key={idx}>
                  <div className="action_btns">
                    <span
                      className="global_edit"
                      onClick={() => openEditModal(id)}
                    >
                      <img src={ICON_EDIT_WHITE_IMG} alt="edit" />
                    </span>
                    {isDelete && (
                      <span
                        className="global_close ml10"
                        onClick={() => openEditModal(id, 'delete')}
                      >
                        <img src={ICON_RED_CANCEL_IMG} alt="delete" />
                      </span>
                    )}
                  </div>
                </td>
              );
            } else if (item.type === 'customTableData') {
              content = <td key={idx}>{item.value}</td>;
            }
          } else {
            content = placeholder;
          }
          return content;
        })}
      </tr>

      {isChosen && ItemDetails && (
        <tr>
          <td colSpan={props.data.length} className="p-0">
            <ItemDetails />
          </td>
        </tr>
      )}
    </>
  );
};
