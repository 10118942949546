import React from 'react';
import { Col, DropdownButton, Form, Row, Dropdown } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { phoneFormatter, validatePhone } from '../../utils/commonUtility';
import { CustomModal, Button } from '../../urgently-component';
import '../technicians/technicians.scss';
import { getFleetData } from '../../saga/fleet/fleetSelector';
import { config } from '../../utils/config';
import { Categories, TruckType } from '../../types/reducerTypes';

enum MODAL_TYPE {
  driver = 'driver',
  vehicle = 'vehicle',
}

interface Props {
  show: boolean;
  handleClose: () => void;
  modalType: string;
  operation: string;
  data: {
    uid?: any;
    access?: string;
    index: number;
    firstName: string;
    name: string;
    licencePlate: string;
    truckType: string;
    truckTypeId: number;
    lastName: string;
    externalId: string;
    phoneNumber: string;
    picture: string;
    services?: number[];
    file: {};
    email: string;
  };
  saveChanges: (data: any) => void;
}

export const AddEditModal: React.FC<Props> = (props: Props) => {
  // console.log('AddEditModal props: ', props);
  const fleetData = useSelector(getFleetData, shallowEqual);
  const truckTypes = fleetData.truckTypes;

  const [dispatchServices, setDispatchServices] = React.useState(
    [] as Categories[]
  );

  let file: any = null;
  let fileName = '';

  const [driverData, setDriverData] = React.useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    externalId: '',
    picture: '',
    index: -1,
    file: {},
  });
  const [bgCheckOption, setBgCheckOption] = React.useState(false);
  const [vehicleData, setVehicleData] = React.useState({
    picture: '',
    truckType: '',
    name: '',
    licencePlate: '',
    externalId: '',
    truckTypeId: 0,
    index: -1,
    file: {},
  });

  const [phoneNumber, setPhoneNumber] = React.useState('');

  const saveData = (): any => {
    let tmpData: any;
    if (props.modalType === MODAL_TYPE.driver) {
      tmpData = Object.assign({}, driverData);
      tmpData.phoneNumber = phoneNumber;
      tmpData.isBGCheckRequested = bgCheckOption;
      props.saveChanges(tmpData);
    } else if (props.modalType === MODAL_TYPE.vehicle) {
      tmpData = Object.assign({}, vehicleData);
      tmpData.services = dispatchServices
        .filter((service: Categories) => service.checked)
        .map((service: Categories) => service.id);
      props.saveChanges(tmpData);
    }
  };

  const uploadImg = (e: React.ChangeEvent<HTMLInputElement>): void => {
    file = e.target.files?.[0];
    fileName = file?.name;
    const divId = document.getElementById('profileImg');
    if (divId && file) {
      divId.style.background = 'url(' + URL.createObjectURL(file) + ')';
    }
    if (divId && props.operation === 'add') {
      divId.style.backgroundSize = 'cover';
    }
    if (fileName && file) {
      if (props.modalType === MODAL_TYPE.driver) {
        setDriverData({
          ...driverData,
          picture: fileName,
          file,
        });
      } else if (props.modalType === MODAL_TYPE.vehicle) {
        setVehicleData({
          ...vehicleData,
          picture: fileName,
          file,
        });
      }
    }
  };

  const autoPhoneFormat = (event: any): any => {
    const item =
      event && event.clipboardData && event.clipboardData.items
        ? event.clipboardData.items[0]
        : null;
    if (item && item.kind === 'string' && item.type === 'text/plain') {
      let formattedPhone = '';
      const phone: any = event.clipboardData.getData('text/plain');
      const data = phone.toString().match(/\d+/g)?.join('');
      if (data?.length !== phone.length) {
        setPhoneNumber(phone);
        return;
      }
      if (isNaN(parseInt(data))) {
        setPhoneNumber('');
      } else {
        [...data].forEach((digit: any) => {
          if (isNaN(parseInt(digit))) {
            setPhoneNumber('');
          } else {
            formattedPhone += digit;
            formattedPhone = phoneFormatter(formattedPhone);
          }
        });
        if (formattedPhone.length === config.MAX_PHONE_LENGTH) {
          setPhoneNumber(formattedPhone);
        } else {
          if (formattedPhone.length > config.MAX_PHONE_LENGTH) {
            setPhoneNumber(
              formattedPhone.slice(
                0,
                formattedPhone.length -
                  (formattedPhone.length - config.MAX_PHONE_LENGTH)
              )
            );
          } else {
            setPhoneNumber(formattedPhone);
          }
        }
      }
    }
  };

  React.useEffect(() => {
    if (props.modalType === MODAL_TYPE.driver) {
      setPhoneNumber(props.data.phoneNumber || '');
      setDriverData(props.data);
    } else if (props.modalType === MODAL_TYPE.vehicle) {
      setVehicleData(props.data);

      if (props.operation === 'edit' && props.data.services?.length) {
        //  TODO
        // setUpdatedServices()
        const tempServices: Categories[] = JSON.parse(
          JSON.stringify(fleetData.categories)
        ).map((service: Categories) => {
          service.checked = false;
          return service;
        });
        props.data.services.forEach((service: number) => {
          for (const i in tempServices) {
            if (tempServices[i].id === service) {
              tempServices[i].checked = true;
              break;
            }
          }
        });
        setDispatchServices(tempServices);
      }
    }
    const divId = document.getElementById('profileImg');
    if (props?.data?.picture && divId) {
      divId.style.background = 'url(' + props.data.picture + ')';
      // divId.style.backgroundSize = 'cover';
    }
  }, [props.data]);

  let submitDisabled = false;
  if (props.modalType === MODAL_TYPE.driver) {
    if (
      driverData.firstName &&
      driverData.lastName &&
      phoneNumber.length === config.MAX_PHONE_LENGTH
    )
      submitDisabled = false;
    else submitDisabled = true;
  } else if (props.modalType === MODAL_TYPE.vehicle) {
    if (
      vehicleData.name &&
      vehicleData.truckType &&
      vehicleData.truckTypeId &&
      vehicleData.licencePlate &&
      vehicleData.externalId
    )
      submitDisabled = false;
    else submitDisabled = true;
  }

  return (
    <CustomModal
      show={props.show}
      closeModal={props.handleClose}
      title={props.operation + ' ' + props.modalType}
      actionButtons={
        <>
          <Button
            variant="basic"
            clx="button_secondary black btn_gap"
            data-testid="btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="basic"
            clx="button_primary_blue"
            data-testid="btn-submit"
            disabled={submitDisabled}
            onClick={saveData}
          >
            {props.operation === 'add' ? 'Add' : ''}
            {props.operation === 'edit' ? 'Save' : ''}
          </Button>
        </>
      }
    >
      <div className="">
        <Form className="custom_form">
          {(props.modalType === MODAL_TYPE.driver ||
            props.modalType === MODAL_TYPE.vehicle) && (
            <Col>
              <div className="profile_img driver_profile_img">
                <div
                  className={`img_box ${
                    props.operation === 'edit' ? 'edit' : ''
                  }`}
                >
                  <div className="icon" id="profileImg" />
                  {props.operation === 'add' && (
                    <input
                      id="upload"
                      data-testid="profile-img"
                      accept=".jpg,.jpeg,.png,.gif"
                      type="file"
                      required={false}
                      onChange={uploadImg}
                    />
                  )}
                  {props.operation === 'edit' && (
                    <div className="edit_driver">
                      Edit
                      <input
                        id="upload"
                        data-testid="profile-img"
                        accept=".jpg,.jpeg,.png,.gif"
                        type="file"
                        required={false}
                        onChange={uploadImg}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          )}
          <Col>
            {props.modalType === MODAL_TYPE.driver && (
              <Row>
                <Form.Group>
                  <Form.Label htmlFor="first-name">First Name</Form.Label>
                  <Form.Control
                    id="first-name"
                    className="cap"
                    data-testid="first-name"
                    type="text"
                    value={driverData.firstName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDriverData({
                        ...driverData,
                        firstName: event.target.value,
                      });
                    }}
                    placeholder="First Name"
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label htmlFor="last-name">Last Name</Form.Label>
                  <Form.Control
                    id="last-name"
                    className="cap"
                    data-testid="last-name"
                    type="text"
                    value={driverData.lastName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDriverData({
                        ...driverData,
                        lastName: event.target.value,
                      });
                    }}
                    placeholder="Last Name"
                  />
                </Form.Group>
              </Row>
            )}
            {props.modalType === MODAL_TYPE.vehicle && (
              <Row>
                <Form.Group>
                  <Form.Label htmlFor="name">Name</Form.Label>
                  <Form.Control
                    id="name"
                    className="cap"
                    data-testid="name"
                    type="text"
                    value={vehicleData.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setVehicleData({
                        ...vehicleData,
                        name: event.target.value,
                      })
                    }
                    placeholder="Name"
                  />
                </Form.Group>

                <Col md={12} className="pl0 pr0">
                  <Form.Group>
                    <Form.Label htmlFor="Type">Type</Form.Label>
                    <DropdownButton
                      className="custom_dropdown new"
                      title={
                        vehicleData.truckType ? vehicleData.truckType : 'Select'
                      }
                      id="truck-type"
                      data-testid="truck-type"
                    >
                      {truckTypes?.map((truck: TruckType) => (
                        <Dropdown.Item
                          key={truck.id}
                          data-testid={truck.type}
                          onSelect={() =>
                            setVehicleData({
                              ...vehicleData,
                              truckType: truck.type,
                              truckTypeId: truck.id,
                            })
                          }
                        >
                          {truck.type}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              {props.modalType === MODAL_TYPE.driver && (
                <Form.Group>
                  <Form.Label htmlFor="phone-number">Phone Number</Form.Label>
                  <Form.Control
                    id="phone-number"
                    data-testid="phone-number"
                    type="text"
                    value={phoneNumber}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      validatePhone(e, phoneNumber)
                    }
                    onPaste={autoPhoneFormat}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value.length > 14) {
                        return;
                      }
                      if (event.target.value.length < phoneNumber.length) {
                        setPhoneNumber(event.target.value);
                      } else {
                        setPhoneNumber(phoneFormatter(event.target.value));
                      }
                    }}
                    placeholder="Phone Number"
                  />
                </Form.Group>
              )}

              {props.modalType === MODAL_TYPE.vehicle && (
                <Form.Group>
                  <Form.Label htmlFor="license-plate">Licence Plate</Form.Label>
                  <Form.Control
                    id="license-plate"
                    data-testid="license-plate"
                    type="text"
                    value={vehicleData.licencePlate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setVehicleData({
                        ...vehicleData,
                        licencePlate: event.target.value,
                      })
                    }
                    placeholder="Licence Plate"
                  />
                </Form.Group>
              )}
              {props.modalType === MODAL_TYPE.vehicle &&
                props.operation === 'add' && (
                  <Form.Group>
                    <Form.Label htmlFor="external-id">External Id</Form.Label>
                    <Form.Control
                      id="external-id"
                      data-testid="external-id"
                      type="text"
                      value={driverData.externalId || vehicleData.externalId}
                      // disabled={props.operation === 'edit'}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setVehicleData({
                          ...vehicleData,
                          externalId: event.target.value,
                        });
                      }}
                      placeholder="External Id"
                    />
                  </Form.Group>
                )}

              {props.modalType === MODAL_TYPE.vehicle &&
                props.operation === 'edit' && (
                  <>
                    <Form.Group className="mb10">
                      <Form.Label htmlFor="services">Services</Form.Label>
                    </Form.Group>
                    {dispatchServices.map((service: Categories) => {
                      return (
                        <Form.Group
                          key={service.id}
                          controlId={`${service.id}`}
                        >
                          <Form.Check
                            type="checkbox"
                            label={service.value}
                            data-testid={`ds-${service.id}`}
                            className="checkbox_area"
                            checked={service.checked}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const updateServices: Categories[] =
                                dispatchServices.map((services: Categories) => {
                                  if (services.id === Number(event.target.id)) {
                                    services.checked = event.target.checked;
                                  }
                                  return services;
                                });
                              setDispatchServices(updateServices);
                            }}
                          />
                        </Form.Group>
                      );
                    })}
                  </>
                )}

              {config.isBackgroundCheckEnabled() &&
                props.modalType === MODAL_TYPE.driver &&
                props.operation === 'add' && (
                  <>
                    <div className="bg_check_area">
                      <div className="bg_check_title">Background Check</div>
                      <div className="bg_check_info">
                        In order to be eligible for some jobs, all drivers must
                        enroll in our background check program.
                      </div>
                    </div>

                    <Form.Group
                      className="mb10 mt5"
                      controlId="bgCheckCheckbox"
                    >
                      <Form.Check
                        type="checkbox"
                        label="Send background check link"
                        className="checkbox_area"
                        data-testid="bg-check"
                        checked={bgCheckOption}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setBgCheckOption(event.target.checked);
                        }}
                      />
                    </Form.Group>
                  </>
                )}
            </Row>
          </Col>
        </Form>
      </div>
    </CustomModal>
  );
};

export default AddEditModal;
