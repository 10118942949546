import { NotificationTypes } from '../../types/reducerTypes';
import {
  SHOW_NOTIFICATION_SUCCESS,
  HIDE_NOTIFICATION,
  SHOW_STATIC_NOTIFICATION,
  CLEAR_STATIC_NOTIFICATIONS,
} from './notificationsActionTypes';
const notificationsInitialState: NotificationTypes = {
  static: [],
  dinamic: [],
};

export const notificationReducer = (
  state = notificationsInitialState,
  action: any
): object => {
  switch (action.type) {
    case SHOW_NOTIFICATION_SUCCESS:
      return {
        ...state,
        dinamic: [action.payload.notification, ...state.dinamic.slice(0, 3)],
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        dinamic: action.payload.updatedNotifications,
      };
    case SHOW_STATIC_NOTIFICATION:
      return {
        ...state,
        static: [...state.static, action.payload],
      };
    case CLEAR_STATIC_NOTIFICATIONS:
      return {
        ...state,
        static: [],
      };
    default:
      return state;
  }
};
