import React from 'react';
import { Col, DropdownButton, Form, Row, Dropdown } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CustomModal, Button } from '../../urgently-component';
import '../technicians/technicians.scss';
import { getFleetData } from '../../saga/fleet/fleetSelector';
import { DriverDTO, VehicleDTO } from '../../types/reducerTypes';
import AlertWrapper from '../alertsWrapper/alertsWrapper';
import * as fleetAction from '../../saga/fleet/fleetActionTypes';

export const AssignDriverVehicleModal: React.FC<Props> = (props) => {
  const { driverOrVehicle, assignType, show } = props;

  const fleetData = useSelector(getFleetData, shallowEqual);
  const [selectedItem, setItem] = React.useState(null as any);

  const alertData: { type: string; message: string } = {
    type: '',
    message: '',
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    setItem(null as any);
    if (assignType === 'VEHICLE') {
      dispatch({ type: fleetAction.GET_FLEET_VEHICLE_LIST });
    } else if (assignType === 'DRIVER') {
      dispatch({ type: fleetAction.GET_FLEET_DRIVER_LIST });
    }
  }, [show]);

  let title = '';
  let displayName = 'Select';
  if (assignType === 'VEHICLE') {
    title = 'Assign Vehicle';
    if (selectedItem) {
      displayName = selectedItem?.truckInfo?.name
        ? selectedItem.truckInfo.name
        : 'No Vehicle name entered';
      displayName += selectedItem?.truckInfo?.truckType
        ? ` (${selectedItem.truckInfo.truckType})`
        : '';
    }
    if (fleetData.vehicleList.length === 0) {
      alertData.type = 'danger';
      alertData.message =
        'No trucks currently added. To link a driver and a truck please add a truck.';
    }
  } else if (assignType === 'DRIVER') {
    title = 'Assign Driver';
    if (selectedItem) {
      displayName = `${selectedItem.firstName || ''} ${
        selectedItem.lastName || ''
      }`;
    }
    if (fleetData.driverList.length === 0) {
      alertData.type = 'danger';
      alertData.message =
        'No drivers currently added. To link a driver and a truck please add a driver.';
    }
  }

  const prepareLinkDriverVehicleData = (): void => {
    let driverUid, vehicleUid, externalId;
    const driverName = [];
    let vehicleName = '';
    if (assignType === 'VEHICLE') {
      const driverInfo: DriverDTO = driverOrVehicle;
      const selectedVehicle: VehicleDTO = selectedItem;
      if (driverInfo.firstName) driverName.push(driverInfo.firstName);
      if (driverInfo.lastName) driverName.push(driverInfo.lastName);
      vehicleName = selectedVehicle.truckInfo.name;
      driverUid = driverInfo.uid || '';
      vehicleUid = selectedVehicle.truckInfo?.uid || '';
      externalId = driverInfo.externalId || '';
    } else if (assignType === 'DRIVER') {
      const vehicleInfo: VehicleDTO = driverOrVehicle;
      const selectedDriver: DriverDTO = selectedItem;
      if (selectedDriver.firstName) driverName.push(selectedDriver.firstName);
      if (selectedDriver.lastName) driverName.push(selectedDriver.lastName);
      vehicleName = vehicleInfo.truckInfo.name;
      driverUid = selectedDriver.uid || '';
      vehicleUid = vehicleInfo.truckInfo?.uid || '';
      externalId = selectedDriver.externalId || '';
    }

    props.linkDriverVehicle({
      assignType,
      driverUid,
      vehicleUid,
      externalId,
      driverName,
      vehicleName,
    });
  };

  return (
    <CustomModal
      show={props.show}
      closeModal={props.handleClose}
      title={title}
      actionButtons={
        <>
          <Button
            variant="basic"
            data-testid="btn-cancel"
            clx="button_secondary black btn_gap"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="basic"
            clx="button_primary_blue"
            disabled={!selectedItem}
            data-testid="btn-submit"
            onClick={prepareLinkDriverVehicleData.bind(this, selectedItem)}
          >
            Add
          </Button>
        </>
      }
    >
      <Form className="custom_form">
        <Col>
          <Row>
            <AlertWrapper type={alertData.type} message={alertData.message}>
              <Form.Group>
                <Form.Label htmlFor="link">{assignType}</Form.Label>
                {assignType === 'VEHICLE' ? (
                  <DropdownButton
                    className="custom_dropdown new"
                    title={displayName}
                    id="truck-type"
                    data-testid="truck-type"
                  >
                    {fleetData.vehicleList.map(
                      (vehicle: VehicleDTO, idx: number) => (
                        <Dropdown.Item
                          key={vehicle.truckInfo.id}
                          data-testid={`vehicle-${idx + 1}`}
                          onSelect={() => {
                            setItem(vehicle);
                          }}
                        >
                          {vehicle.truckInfo.name
                            ? vehicle.truckInfo.name
                            : 'No Vehicle name entered'}{' '}
                          ({vehicle.truckInfo.truckType})
                        </Dropdown.Item>
                      )
                    )}
                  </DropdownButton>
                ) : (
                  <DropdownButton
                    className="custom_dropdown new"
                    title={displayName}
                    id="driver-name"
                    data-testid="driver-name"
                  >
                    {fleetData.driverList.map(
                      (driver: DriverDTO, idx: number) => (
                        <Dropdown.Item
                          key={driver.id}
                          data-testid={`driver-${idx + 1}`}
                          onSelect={() => {
                            setItem(driver);
                          }}
                        >
                          {driver.firstName || ''} {driver.lastName || ''}
                        </Dropdown.Item>
                      )
                    )}
                  </DropdownButton>
                )}
              </Form.Group>
            </AlertWrapper>
          </Row>
        </Col>
      </Form>
    </CustomModal>
  );
};

interface Props {
  show: boolean;
  driverOrVehicle: any;
  index: number;
  assignType: string;
  handleClose: () => void;
  linkDriverVehicle: (data: any) => void;
}

export default AssignDriverVehicleModal;
