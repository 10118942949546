import React, { useState } from 'react';

import './table.scss';

export interface Props {
  data: any[];
  renderHeader: any;
  renderItem: any;
  noDataFound: any;
  clx?: string;
  tableName?: string;
  addEditModal?: (
    modalType: string,
    operation: string,
    show: boolean,
    data: any
  ) => void;
  deleteScreen?: (modalType: string, name: string, index: number) => void;
  financeDetail?: (id: number) => void;
}

export const Table: React.FC<Props> = (props) => {
  const {
    data,
    renderHeader: RenderHeader,
    renderItem: RenderItem,
    noDataFound: NoDataFound,
    clx,
    tableName,
    deleteScreen,
    financeDetail,
  } = props;
  const [chosenTableItem, setChosenTableItem] = useState<number>(-1);

  const onToggleTableItem = (jobId: number): void => {
    setChosenTableItem((prev) => {
      return prev === jobId ? -1 : jobId;
    });
  };

  return (
    <>
      <table className={`table w-100 ${clx ?? ''}`}>
        <thead>
          <RenderHeader tableName={tableName} />
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((item: any, index: number) => {
              if (item) {
                return (
                  <RenderItem
                    key={index}
                    keyId={index}
                    item={item}
                    onToggleTableItem={onToggleTableItem}
                    chosenTableItem={chosenTableItem}
                    tableName={tableName}
                    deleteScreen={deleteScreen}
                    financeDetail={financeDetail}
                  />
                );
              }
            })
          ) : (
            <NoDataFound />
          )}
        </tbody>
      </table>
    </>
  );
};
