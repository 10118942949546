import Bowser from 'bowser';
import { getAuthData } from '../saga/login/loginSelectors';
import { AuthTypes } from '../types/reducerTypes';
import API from '../utils/API';
import { config } from '../utils/config';
import { HTTP_POST, TRACK_DISPATCH_EVENTS } from './API';

// import { GA_EVENT_TIMEOUT } from 'utility/api-config'
// var gtag: Gtag.Gtag;
let gaConfig: any = {
  scroll: false,
  first_visit: false,
  user_engagement: false,
  session_start: false,
  send_page_view: false,
  cookie_flags: 'Secure',
};
// { send_page_view : false } stops GA script from sending a page view automatically on first load
// We need to avoid sending page view automatically from GA script because of SPA

const GTAG_ID: string = process.env.REACT_APP_GTAG_ID || '';
const setTrackingConfig = (): void => {
  // console.log('setTrackingConfig called');
  const user: AuthTypes = config.user();
  // The params that are set here other than send_page_view will be sent with all the events
  if (user?.uid && user?.providerUid && user?.username) {
    gaConfig = {
      ...gaConfig,
      user_id: user.uid,
      provider_uid: user.providerUid,
      username: user.username,
      authority: user?.authority,
    };
  }
  // @ts-ignore
  const gtag: Gtag.Gtag = window.gtag;
  if (gtag && GTAG_ID) {
    gtag('config', GTAG_ID, gaConfig);
  }
};

const gaTrackPage = (params: any): void => {
  // console.log('gaTrackPage() called: ', params);
  // @ts-ignore
  const gtag: Gtag.Gtag = window.gtag;
  if (gtag) {
    gtag('event', 'page_view', params);
  }
};

const gaTrackEvent = (name: string, params: any): void => {
  // console.log(`gaTrackEvent() called: name: ${name}, params: `, params);
  // @ts-ignore
  const gtag: Gtag.Gtag = window.gtag;
  if (gtag) {
    gtag('event', name, params);
  }
};

const trackPage = (
  eventId: number,
  eventValue: string,
  jobNumber?: number | null,
  extraParams?: any
): void => {
  // console.warn('trackPage called');
  const user: AuthTypes = config.user();
  const params: any = {
    eventId,
    eventValue,
  };
  let gaParams: any = {};
  if (jobNumber) {
    params.jobNumber = jobNumber;
    gaParams.jobNumber = jobNumber;
  }
  if (extraParams) {
    params.extraParams = extraParams;
    gaParams = {
      ...gaParams,
      ...extraParams,
    };
  }
  if (user?.uid && user?.providerUid && user?.email) {
    gaParams.uid = user.uid;
    gaParams.provider_id = user?.providerId;
    gaParams.provider_uid = user.providerUid;
    gaParams.name = user?.name;
    gaParams.email = user.email;
  }

  params.eventType = 'PAGE_LOAD';
  sendEvent(params);

  const page_title = eventValue
    .toLowerCase()
    .replace('on_', '')
    .replace(/_/g, '-');
  gaTrackPage({
    page_title,
    ...gaParams,
  });
};

const trackEvent = (
  eventId: number,
  eventValue: string,
  jobNumber?: number | null,
  extraParams?: any
): any => {
  // console.warn('trackEvent called');
  const user: AuthTypes = config.user();
  const params: any = {
    eventId,
    eventValue,
  };
  let gaParams: any = {};
  if (jobNumber) {
    params.jobNumber = jobNumber;
    gaParams.jobNumber = jobNumber;
  }
  if (extraParams) {
    params.extraParams = extraParams;
    gaParams = {
      ...gaParams,
      ...extraParams,
    };
  }
  if (user?.providerUid && user?.username) {
    gaParams.provider_id = user?.providerId;
    gaParams.provider_uid = user.providerUid;
    gaParams.username = user.username;
    gaParams.name = user?.name;
    gaParams.email = user.email;
  }
  params.eventType = 'ACTION';
  sendEvent(params);
  gaTrackEvent(eventValue, gaParams);
};

const trackPubNub = (
  eventId: string,
  eventValue: string,
  jobNumber: number,
  extraParams: any
): void => {
  // console.warn('trackPubNub called');
  const user: AuthTypes = config.user();
  const params: any = {
    eventId,
    eventValue,
  };
  let gaParams: any = {};
  if (jobNumber) {
    params.jobNumber = jobNumber;
    gaParams.jobNumber = jobNumber;
  }
  if (extraParams) {
    params.extraParams = extraParams;
    gaParams = {
      ...gaParams,
      ...extraParams,
    };
  }
  if (user?.providerUid && user?.username) {
    gaParams.provider_id = user?.providerId;
    gaParams.provider_uid = user.providerUid;
    gaParams.username = user.username;
    gaParams.name = user?.name;
    gaParams.email = user.email;
  }
  params.eventType = 'PUBNUB';
  sendEvent(params);
  gaTrackEvent(eventValue, gaParams);
};

const sendEvent = (data: any): void => {
  // console.log('sendEvent called!');
  const user: AuthTypes = config.user();
  const browser: any = Bowser.getParser(window.navigator.userAgent);
  const payload = {
    ...data,
    entityId: user?.providerUid || '',
    entityType: 'PROVIDER',
    // version: config.BUILDVERSION,
    os: browser?.parsedResult?.os?.name,
    osVersion: browser?.parsedResult?.browser?.version,
    product: 'dispatch_pro',
    product_version: 'fm_microapp',
  };

  if (data.jobNumber) {
    payload.jobNumber = data.jobNumber;
  }

  if (data.extraParams) {
    payload.extraParams = JSON.stringify(data.extraParams);
  }
  // console.error(eventUrl, payload);
  if (user?.authToken) {
    API(HTTP_POST, TRACK_DISPATCH_EVENTS, payload, true);
  }
};

export const AppEvents = {
  trackPage: trackPage,
  trackEvent: trackEvent,
  trackPubNub: trackPubNub,
  setTrackingConfig: setTrackingConfig,
};
