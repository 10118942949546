/* NOTE: un-comment below line and it will show how unnecessary re-render of component in console log*/
// import './wdyr.ts';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import configureStore from './store/store';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from './utils/history';
import 'bootstrap/scss/bootstrap.scss';
import './index.scss';
import throttle from 'lodash/throttle';
import { loadState, saveState } from './utils/localStorage';
import { pubnubConnect } from './utils/pubnubUtility';
import { config } from './utils/config';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ErrorFallback from './containers/errorFallback';
// import reportWebVitals from './reportWebVitals';

// const persistedState = loadState();
// const store = configureStore(persistedState);
const store = configureStore();
// store.subscribe(
//   throttle(() => {
//     const { authentication, config } = store.getState();
//     saveState({ authentication, config });
//   }, 1000)
// );

store.subscribe(
  throttle(() => {
    const { authentication } = store.getState();
    if (authentication?.authData?.authToken) {
      setTimeout(function () {
        pubnubConnect();
      }, 1000);
    }
  }, 1000)
);

// Note : sentry initialization code
if (
  process.env.NODE_ENV === 'production' &&
  !window.location.hostname.includes('dev-')
) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: window.location.hostname.includes('qa-') ? 'dev' : 'prod',
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={ErrorFallback}>
    <Router history={history}>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
    ,
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// serviceWorker.unregister();
