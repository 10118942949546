export const GET_TRUCK_TYPES = 'GET_TRUCK_TYPES';
export const GET_TRUCK_TYPES_SUCCESS = 'GET_TRUCK_TYPES_SUCCESS';

export const GET_FLEET_DRIVER_LIST = 'GET_FLEET_DRIVER_LIST';
export const GET_FLEET_DRIVER_LIST_SUCCESS = 'GET_FLEET_DRIVER_LIST_SUCCESS';

export const GET_FLEET_VEHICLE_LIST = 'GET_FLEET_VEHICLE_LIST';
export const GET_FLEET_VEHICLE_LIST_SUCCESS = 'GET_FLEET_VEHICLE_LIST_SUCCESS';

export const UPDATE_DRIVER = 'UPDATE_DRIVER';
export const ADD_DRIVER = 'ADD_DRIVER';
export const DELETE_DRIVER = 'DELETE_DRIVER';

export const DELETE_VEHICLE = 'DELETE_VEHICLE';
export const ADD_VEHICLE = 'ADD_VEHICLE';
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';

export const REGENERATE_ACTIVATION_CODE = 'REGENERATE_ACTIVATION_CODE';
export const REGENERATE_ACTIVATION_CODE_SUCCESS =
  'REGENERATE_ACTIVATION_CODE_SUCCESS';
export const SEND_TEXT_LINK = 'SEND_TEXT_LINK';

export const CHANGE_DRIVER_SHIFT = 'CHANGE_DRIVER_SHIFT';
export const CHANGE_DRIVER_SHIFT_SUCCESS = 'CHANGE_DRIVER_SHIFT_SUCCESS';
export const CHANGE_DRIVER_SHIFT_PUBNUB = 'CHANGE_DRIVER_SHIFT_PUBNUB';

export const CHANGE_VEHICLE_SHIFT = 'CHANGE_VEHICLE_SHIFT';
export const CHANGE_VEHICLE_SHIFT_SUCCESS = 'CHANGE_VEHICLE_SHIFT_SUCCESS';
export const CHANGE_VEHICLE_SHIFT_PUBNUB = 'CHANGE_VEHICLE_SHIFT_PUBNUB';

export const START_SHIFT = 'START_SHIFT';
export const END_SHIFT = 'END_SHIFT';

export const LINK_DRIVER_VEHICLE = 'LINK_DRIVER_VEHICLE';

export const GET_SERVICE_CATEGORIES = 'GET_SERVICE_CATEGORIES';
export const GET_SERVICE_CATEGORIES_SUCCESS = 'GET_SERVICE_CATEGORIES_SUCCESS';

export const UPDATE_SERVICE_CATEGORIES = 'UPDATE_SERVICE_CATEGORIES';
export const UPDATE_VEHICLE_DTO = 'UPDATE_VEHICLE_DTO';

export const SEND_BG_CHECK_INVITATION = 'SEND_BG_CHECK_INVITATION';
export const SEND_BG_CHECK_INVITATION_SUCCESS =
  'SEND_BG_CHECK_INVITATION_SUCCESS';

export const GET_DRIVERS_BG_CHECK_LIST = 'GET_DRIVERS_BG_CHECK_LIST';
export const GET_DRIVERS_BG_CHECK_LIST_SUCCESS =
  'GET_DRIVERS_BG_CHECK_LIST_SUCCESS';

export const BUSINESS_INFO_REQUEST = 'BUSINESS_INFO_REQUEST';
export const BUSINESS_INFO_SUCCESS = 'BUSINESS_INFO_SUCCESS';
