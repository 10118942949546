//import { printLog } from './logging';
import { object, string, is } from 'superstruct';

const MessagePayloadSuperStruct = object({
  type: string(),
  screen: string(),
  data: object(),
});

interface MessagePayload {
  type: string;
  screen: string;
  data: any;
}

interface CallbackType {
  (params: any): void;
}

export const EventName = {
  ANGULAR_INIT_DATA: 'ANGULAR_INIT_DATA',
  REACT_INIT_FLEET_ASSETS: 'REACT_INIT_FLEET_ASSETS',
  ANGULAR_SHOW_ERROR: 'ANGULAR_SHOW_ERROR',
  UPDATE_VEHICLE_MANAGEMENT_FLAG: 'UPDATE_VEHICLE_MANAGEMENT_FLAG',
};

export const ScreenName = {
  FLEET_ASSETS: 'Fleet Assets',
};

export const postMessage: MessagePayload = {
  type: EventName.REACT_INIT_FLEET_ASSETS,
  screen: ScreenName.FLEET_ASSETS,
  data: {},
};

export const updateVehicleManagementPostMessage: MessagePayload = {
  type: EventName.UPDATE_VEHICLE_MANAGEMENT_FLAG,
  screen: ScreenName.FLEET_ASSETS,
  data: {},
};

const Callbacks = {} as any;
export const Events = {
  on: (at: string, callback: CallbackType) => {
    return Events.listen(at, callback);
  },
  listen: (at: string, callback: CallbackType) => {
    /*eslint-disable eqeqeq*/
    if (at == '') {
      return false;
    }
    Callbacks[at] = callback;
    // return id;
  },
  t: (at: string, data: any) => {
    return Events.trigger(at, data);
  },
  trigger: (at: string, data: any) => {
    data = data || '';
    const method = Callbacks[at];
    //printLog('obj=====', method);
    method(data);
  },
  rm: (at: string) => {
    return Events.remove(at);
  },
  remove: (at: string) => {
    delete Callbacks[at];
  },
  removeAll: (at: string) => {
    delete Callbacks[at];
  },
};

const isSecureRequest = (message: MessagePayload): any => {
  return is(message, MessagePayloadSuperStruct);
};

function getParentAppUrl(): string {
  let url = document.referrer;
  if (url) {
    url = document.referrer.slice(0, -1);
  } else if (document.location?.ancestorOrigins?.length) {
    url = document.location.ancestorOrigins[0];
  }
  return url;
}

export function sendMessage(message: MessagePayload): void {
  console.log('referrer: ', document.referrer);
  if (getParentAppUrl()) {
    window.parent.postMessage(JSON.stringify(message), `${getParentAppUrl()}`);
  }
}
/*NOTE: Main post message listner. */
window.addEventListener(
  'message',
  function (message: any) {
    if (message.data) {
      let receivedMessage;
      if (typeof message.data === 'string') {
        receivedMessage = JSON.parse(message.data);
      }
      if (message.origin === getParentAppUrl()) {
        switch (receivedMessage?.type) {
          case EventName.ANGULAR_INIT_DATA:
            if (isSecureRequest(receivedMessage)) {
              Events.trigger(EventName.ANGULAR_INIT_DATA, receivedMessage.data);
            }
            break;
          case EventName.ANGULAR_SHOW_ERROR:
            Events.trigger(EventName.ANGULAR_INIT_DATA, receivedMessage.data);
            break;
          default:
            break;
        }
      }
    }
  },
  false
);
