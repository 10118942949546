import { put, takeEvery, select, call } from 'redux-saga/effects';
import {
  SHOW_NOTIFICATION,
  SHOW_NOTIFICATION_SUCCESS,
  HIDE_NOTIFICATION,
  FORCE_HIDE_NOTIFICATION,
} from './notificationsActionTypes';
import { NotificationDinamicTypes } from '../../types/reducerTypes';

function* hide(id: number): any {
  const notifications = yield select((state) => state.notifications.dinamic);
  if (notifications.some((n: NotificationDinamicTypes) => n.id === id)) {
    const updatedNotifications = notifications.filter(
      (n: NotificationDinamicTypes) => n.id !== id
    );
    yield put({
      type: HIDE_NOTIFICATION,
      payload: { updatedNotifications },
    });
  }
}

export function* forceHideNotification(action: any): any {
  yield call(hide, action.payload);
}

export function* showNotification(action: any): any {
  const duration = action.payload.duration ? action.payload.duration : 5000;
  const notifications = yield select((state) => state.notifications.dinamic);
  let id: number;
  if (notifications.length === 0) {
    id = 1;
  } else {
    id =
      Math.max(...notifications.map((n: NotificationDinamicTypes) => n.id)) + 1;
  }
  yield put({
    type: SHOW_NOTIFICATION_SUCCESS,
    payload: {
      notification: {
        id,
        type: action.payload.type,
        text: action.payload.text,
        duration,
      },
    },
  });
}

function* notificationWatcher(): any {
  yield takeEvery(SHOW_NOTIFICATION, showNotification);
  yield takeEvery(FORCE_HIDE_NOTIFICATION, forceHideNotification);
}

export default notificationWatcher;
