import React from 'react';
import { Container, Col, Alert } from 'react-bootstrap';
import './alertsWrapper.scss';

declare type Variant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light'
  | string;
interface Props {
  type: Variant;
  message: string;
  children: JSX.Element;
}

const AlertsWrapper: React.FC<Props> = (props: Props) => (
  // <Container fluid data-testid="alert-wrapper">
  <>
    {props.type && props.message && (
      <div>
        {/*className="alerts_wrap"*/}
        <Alert variant={props.type}>{props.message}</Alert>
      </div>
    )}
    {props.children}
  </>
  // </Container>
);

export default AlertsWrapper;
