import React from 'react';

export interface Props {
  className?: string;
  cells: any[];
}

export const TableHeader: React.FC<Props> = (props) => {
  return (
    <tr className={props.className}>
      {props.cells.map((cell: any, idx: number) => {
        if (cell && typeof cell === 'string') {
          return (
            <td key={idx} className="p-3">
              {cell}
            </td>
          );
        } else {
          return <td key={idx} className="p-3 action" />;
        }
      })}
    </tr>
  );
};
