import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { CustomModal, Button } from '../../urgently-component';
import '../technicians/technicians.scss';

interface Props {
  show: boolean;
  handleClose: () => void;
  sendBgCheckInvitation: () => void;
}

export const BgCheckWarningPopup: React.FC<Props> = (props: Props) => {
  const [warningDisplayFlag, setWarningDisplayFlag] = React.useState(false);

  function setVisibilityFlag(checked: boolean): void {
    setWarningDisplayFlag(checked);
    localStorage.setItem('bgCheckPopupVisibility', `${checked}`);
  }

  return (
    <CustomModal
      show={props.show}
      closeModal={props.handleClose}
      title={'Please Be Advised'}
      actionButtons={
        <>
          <Button
            variant="basic"
            clx="button_secondary black btn_gap"
            data-testid="btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="basic"
            clx="button_primary_blue"
            data-testid="btn-continue"
            onClick={props.sendBgCheckInvitation}
          >
            Continue
          </Button>
        </>
      }
    >
      <div className="">
        <Form className="custom_form">
          <Col>
            <Row>
              <div className="bg_check_area">
                <div className="bg_check_info">
                  Urgently is being required by our Insurance and OEM Partners
                  to conduct and verify Background Checks on all drivers
                  performing service events for their customers. Therefore, your
                  Drivers performing events for Urgently must complete
                  background checks through our screening partner Checkr.
                  Urgently will apply a one time background check cost to your
                  account for each driver checked. This fee will average
                  $15-$30, depending on your state and county and in some states
                  may be higher. With the exception of the State of California,
                  we will also require a monthly continuous check program at a
                  cost of $2.00 per month per driver enrolled. These costs will
                  be deducted from your {"company's"} payments on jobs performed
                  by the driver.
                  <br />
                  <br />
                  In the event that you have already completed a background
                  check in the last 12 months and wish to furnish that instead
                  of participating in an Urgently check - please email that
                  verification and driver name, provider company name, and
                  dispatch number to{' '}
                  <a style={{ color: '#007bff' }}>bg@urgent.ly</a>
                </div>
              </div>

              <Form.Group className="mb10 mt5" controlId="bgCheckCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Do not show me this again."
                  className="checkbox_area"
                  data-testid="bg-check"
                  checked={warningDisplayFlag}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setVisibilityFlag(event.target.checked)
                  }
                />
              </Form.Group>
            </Row>
          </Col>
        </Form>
      </div>
    </CustomModal>
  );
};

export default BgCheckWarningPopup;
