import React from 'react';

interface Props {
  show: boolean;
  name: string;
  handleClose: () => void;
  deleteAction: () => void;
}

export const DeleteScreen: React.FC<Props> = (props) => {
  return (
    <>
      {props.show && (
        <div className="delete_overlay">
          <div className="pop_overlay dark" />
          <div className="pop_box">
            <div className="title text_capital">Delete driver?</div>
            <div className="sub_title">
              {' '}
              This will remove your ability to assign {props.name} to jobs.{' '}
            </div>
            <form className="form">
              <div className="form-group noGutter d-flex content-center mt15">
                <button
                  type="button"
                  data-testid="dont-delete"
                  className="btns min_width_170 btn_outline_black mr15"
                  onClick={props.handleClose}
                >
                  No, Don’t Delete
                </button>
                <button
                  type="button"
                  data-testid="yes-delete"
                  className="btns btn_red min_width_170"
                  onClick={props.deleteAction}
                >
                  Yes, Delete
                </button>
              </div>
            </form>
            <div className="note">
              <p>This action can not be undone.</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteScreen;
