import { config } from './config';
import PubNub from 'pubnub';
import { printLog } from './logging';
import { getStore } from '../store/store';
import {
  DriverDTO,
  LastVehicleLocation,
  Location,
  VehicleDTO,
} from '../types/reducerTypes';
import {
  CHANGE_DRIVER_SHIFT_PUBNUB,
  CHANGE_VEHICLE_SHIFT_PUBNUB,
} from '../saga/fleet/fleetActionTypes';
let pubNubClient: any;
const pubnubMessageKeys = {
  jobKeys: [
    '3002',
    '3003',
    '3004',
    '3005',
    '3006',
    '3007',
    '3009',
    '3011',
    '3012',
    '3013',
    '3012',
    '3018',
    '3021',
    '3022',
    '3024',
    '3025',
    '3027',
    '3032',
    '3041',
    '3067',
    '4002',
  ],
};

const proceedPubnubData = async (pubnubData: any): Promise<void> => {
  printLog('pubnub procees', pubnubData.message);
  printLog('pubnub message Key:::', pubnubData.message.messageKey);
  console.log('pubnubData: ', pubnubData);
  if (pubnubData?.message?.messageKey) {
    const store = getStore();
    const props = getStore().getState();
    const data = pubnubData.message;
    const response = JSON.parse(data.message);
    console.log(response);
    const actionData = { type: '', data: {} };
    if (pubnubMessageKeys.jobKeys.indexOf(data.messageKey) >= 0) {
      // TODO
      /*if (data.messageKey === 3004) { }*/
    } else if (data.messageKey === '3072' || data.messageKey === '3073') {
      // '3072' -> 'checkInTechnician'
      // '3073' -> 'checkOutTechnician'
      const driverList: DriverDTO[] = [...props.fleet.driverList];
      if (driverList.length > 0) {
        for (let i = 0; i < driverList.length; i++) {
          if (driverList[i] && response.uid === driverList[i].uid) {
            if (data.messageKey === '3072') {
              driverList[i].status = 1;
            } else if (data.messageKey === '3073') {
              driverList[i].status = 0;
            }
            if (driverList[i]) {
              if (driverList[i].lastVehicleLocation) {
                driverList[i].lastVehicleLocation.street = response.street;
                driverList[i].lastVehicleLocation.city = response.city;
                driverList[i].lastVehicleLocation.state = response.state;
                driverList[i].lastVehicleLocationUpdatedAt =
                  response.lastLocationUpdateTimeStamp;
              } else {
                driverList[i].lastVehicleLocation = {} as LastVehicleLocation;
                driverList[i].lastVehicleLocation.street = response.street;
                driverList[i].lastVehicleLocation.city = response.city;
                driverList[i].lastVehicleLocation.state = response.state;
                driverList[i].lastVehicleLocationUpdatedAt =
                  response.lastLocationUpdateTimeStamp;
              }
            }
          }
        }
        actionData.type = CHANGE_DRIVER_SHIFT_PUBNUB;
        actionData.data = driverList;
      }
    } else if (data.messageKey === '3059' || data.messageKey === '3060') {
      // '3059' -> 'checkInTruck'
      // '3060' -> 'checkOutTruck'
      const vehicleList: VehicleDTO[] = [...props.fleet.vehicleList];
      if (vehicleList.length > 0) {
        for (let i = 0; i < vehicleList.length; i++) {
          if (
            vehicleList[i].truckInfo &&
            response.uKey === vehicleList[i].truckInfo.uid
          ) {
            if (data.messageKey === '3059') {
              vehicleList[i].truckInfo.status = 1;
            } else if (data.messageKey === '3060') {
              vehicleList[i].truckInfo.status = 0;
            }
            if (vehicleList[i]) {
              if (vehicleList[i].location) {
                vehicleList[i].location.street = response.street;
                vehicleList[i].location.city = response.city;
                vehicleList[i].location.state = response.state;
                vehicleList[i].location.lastVehicleLocationUpdatedAt =
                  response.lastLocationUpdateTimeStamp;
              } else {
                vehicleList[i].location = {} as Location;
                vehicleList[i].location.street = response.street;
                vehicleList[i].location.city = response.city;
                vehicleList[i].location.state = response.state;
                vehicleList[i].location.lastVehicleLocationUpdatedAt =
                  response.lastLocationUpdateTimeStamp;
              }
            }
          }
        }
        actionData.type = CHANGE_VEHICLE_SHIFT_PUBNUB;
        actionData.data = vehicleList;
      }
    }
    if (actionData.type !== '') {
      store.dispatch(actionData);
    }
  }
};

export const pubnubConnect = (): void => {
  const pubnubConfig = config.getPubnubConfig();
  if (pubnubConfig?.keySet?.subscribeKey !== '') {
    // printLog('pubnubConfig', pubnubConfig);
    pubNubClient = new PubNub(pubnubConfig?.keySet);
    // printLog('pubNubClient', pubNubClient);
    pubNubClient.addListener({
      status: function (statusEvent: any) {
        printLog('pubnub statusEvent', statusEvent);
        if (statusEvent.category === 'PNConnectedCategory') {
          // var payload = {
          //   my: 'payload'
          // };
          // pubnub.publish(
          //   {
          //     message: payload
          //   },
          //   function(status) {
          //     // handle publish response
          //   }
          // );
        }
      },
      message: function (message: any) {
        printLog('pubnub received', message);
        proceedPubnubData(message);
        //   this.props.storePubnub(message);
        // handle message
      },
      presence: function (presenceEvent: any) {
        // handle presence
        printLog('pubnub presence', presenceEvent);
      },
    });
    pubNubClient.subscribe({
      channels: pubnubConfig.channel,
    });
  }
};
export const pubnubDisconnect = (): void => {
  if (pubNubClient) {
    printLog('pubnubDisconnect');
    const pubnubConfig = config.getPubnubConfig();
    pubNubClient.unsubscribe({
      channels: pubnubConfig.channel,
    });
  }
  // if (pubnubConfig) {
  //   const pubNubClient = new PubNub(pubnubConfig?.keySet);
  // }
};
