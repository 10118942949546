import { AuthInitialStateTypes, Configs } from '../../types/reducerTypes';
import { printLog } from '../../utils/logging';
import {
  ANGULAR_AUTH_SUCCESS,
  LOGIN_SUCCESS,
  UPDATE_VEHICLE_MANAGEMENT_FLAG,
} from './loginActionTypes';

export const authInitialState: AuthInitialStateTypes = {
  authData: {
    id: 0,
    authToken: '',
    name: '',
    email: '',
    username: '',
    authority: '',
    providerId: '',
    providerUid: '',
    uid: '',
    jwtDetails: {},
    channel: '',
    chatChannel: '',
    configs: {} as Configs,
  },
};

export const authenticationReducer = (
  state = authInitialState,
  action: any
): object => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        authData: action.response.data[0],
      };
    case ANGULAR_AUTH_SUCCESS:
      // printLog(action.params);
      return {
        ...state,
        authData: {
          ...state.authData,
          ...action.params,
        },
      };
    case UPDATE_VEHICLE_MANAGEMENT_FLAG: {
      return {
        ...state,
        authData: {
          ...state.authData,
          configs: {
            ...state.authData.configs,
            providerPreference: action.providerPreference,
          },
        },
      };
    }
    default:
      return state;
  }
};
