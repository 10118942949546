import React from 'react';
import './technicians.scss';
import { Col, Container, Row } from 'react-bootstrap';
import {
  Table,
  TableHeader,
  TableItem,
  Button,
} from '../../urgently-component';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getFleetData } from '../../saga/fleet/fleetSelector';
import { getAuthData } from '../../saga/login/loginSelectors';
import * as fleetAction from '../../saga/fleet/fleetActionTypes';
import { formatFullPhoneNumber } from '../../utils/commonUtility';
import Bowser from 'bowser';
import AddEditModal from '../addEditModal/addEditModal';
import DeleteScreen from './deleteScreen';
import {
  AddEditModalDTO,
  AuthTypes,
  DriverDTO,
  FleetAssetsTypes,
} from '../../types/reducerTypes';
import moment from 'moment';
import ViewTechnicianModal from './viewTechnicianModal';
import AssignDriverVehicleModal from './assignDriverVehicleModal';
import { config } from '../../utils/config';
import { AppEvents } from '../../constants/analytics';
import { Events } from '../../constants/appEvents';
import BgCheckWarningPopup from './bgCheckWarningModal';

interface Props {}

export const TechnicianListing: React.FC<Props> = (props: Props) => {
  const [driverDetails, setDriverDetails] = React.useState({
    data: [],
  });
  const [showDeleteScreen, setDeleteScreen] = React.useState({
    show: false,
    name: '',
    driver: {} as DriverDTO,
  });
  const addEditModalData: AddEditModalDTO = {
    index: -1,
    firstName: '',
    name: '',
    licencePlate: '',
    truckType: '',
    truckTypeId: -1,
    lastName: '',
    externalId: '',
    phoneNumber: '',
    picture: '',
    file: {},
    email: '',
  };
  const [showAddEditModal, setAddEditModal] = React.useState({
    modalType: '',
    operation: '',
    show: false,
    data: addEditModalData,
  });

  const [showViewModal, setViewModal] = React.useState({
    show: false,
    driver: {} as DriverDTO,
    idx: -1,
  });

  const [showAssignVehicleModal, setAssignVehicleModal] = React.useState({
    show: false,
    assignType: '',
    driver: {} as DriverDTO,
    idx: -1,
  });

  const [bgCheckWarningPopup, setBgCheckWarningPopup] = React.useState({
    show: false,
    driver: {} as DriverDTO,
    idx: -1,
  });

  const fleetData: FleetAssetsTypes = useSelector(getFleetData, shallowEqual);
  const providerData: AuthTypes = useSelector(getAuthData, shallowEqual);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setDriverData();
  }, [fleetData.driverList]);

  React.useEffect(() => {
    setDriverData();
  }, [fleetData.businessInfo]);

  const RenderHeader = (): any => (
    <TableHeader
      className="table_header"
      cells={
        config.isBackgroundCheckEnabled()
          ? [
              '',
              'Driver',
              'Vehicle',
              'Phone Number',
              'Activation Code',
              'Background Check',
              '',
            ]
          : ['', 'Driver', 'Vehicle', 'Phone Number', 'Activation Code', '']
      }
    />
  );

  const NoDataFound = (): any => (
    <tr>
      <td colSpan={7} className="no_data_found" title="no-data-found">
        No Drivers Found
      </td>
    </tr>
  );

  const rowDataForTable = (item: any, keyId: any, tableName: any): any[] => {
    const row = [
      {
        type: 'profile',
        value: item[0],
        initial: `${item[1].data?.firstName?.charAt(0) || ''}${
          item[1].data?.lastName?.charAt(0) || ''
        }`,
      },
      {
        type: 'customTableData',
        value: item[1].renderItem,
      },
      {
        type: 'customTableData',
        value: item[2],
      },
      formatFullPhoneNumber(item[3]),
      item[4],
      {
        type: 'customTableData',
        value: item[5],
      },
    ];
    if (config.isBackgroundCheckEnabled()) {
      row.push({
        type: 'customTableData',
        value: item[6],
      });
    }
    return row;
  };

  const RenderItem = ({
    item,
    keyId,
    tableName,
  }: {
    item: any;
    keyId: any;
    tableName: any;
  }): any => (
    <TableItem
      isChoosable={false}
      isChosen={false}
      tableName={tableName}
      addEditModal={openAddEditModal}
      clx="actions_hover"
      data={rowDataForTable(item, keyId, tableName)}
      id={keyId}
      isDelete={fleetData.driverList[keyId]?.status === 0}
    />
  );

  const setDriverData = (): any => {
    const driverArr: any = [];
    for (let i = 0; i < fleetData.driverList?.length; i++) {
      const tmp: any = [];
      const driverDTO: DriverDTO = fleetData.driverList[i];
      // 0
      tmp.push(driverDTO?.profileInfo?.picture);
      // 1
      tmp.push({
        data: {
          firstName: driverDTO?.firstName,
          lastName: driverDTO?.lastName,
        },
        renderItem: (
          <>
            <div
              className="user_link cap"
              onClick={() => {
                setViewModal({
                  show: true,
                  driver: driverDTO,
                  idx: i,
                });
                AppEvents.trackPage(
                  Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
                    .ON_FLEET_ASSETS_DRIVER_VIEW_MODAL.id,
                  Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
                    .ON_FLEET_ASSETS_DRIVER_VIEW_MODAL.name
                );
              }}
            >
              {driverDTO?.firstName ? driverDTO.firstName + ' ' : ''}{' '}
              {driverDTO?.lastName || ''}
              {!driverDTO.firstName && !driverDTO.lastName ? 'N/A' : ''}
            </div>
            <div
              className={`shift_label ${
                driverDTO.status === 0 ? 'offshift' : ''
              }`}
            >
              {driverDTO.status === 0
                ? 'Off Shift'
                : driverDTO.status === 1
                ? 'On Shift'
                : 'Busy'}
            </div>
          </>
        ),
      });
      // 2
      tmp.push(
        <div className="cap">
          <div>
            {driverDTO.currentVehicle?.name || ''}
            {driverDTO.currentVehicle?.truckType
              ? ' (' + driverDTO.currentVehicle.truckType + ')'
              : ''}
          </div>
          <div className="fsize14">
            {driverDTO.lastVehicleLocation?.street ||
            driverDTO.lastVehicleLocation?.city ||
            driverDTO.lastVehicleLocation?.state
              ? `${
                  driverDTO.lastVehicleLocation.street
                    ? driverDTO.lastVehicleLocation.street + ', '
                    : ''
                }
               ${
                 driverDTO.lastVehicleLocation.city
                   ? driverDTO.lastVehicleLocation.city + ', '
                   : ''
               }
               ${driverDTO.lastVehicleLocation.state || ''} - ${moment(
                  driverDTO.lastVehicleLocationUpdatedAt
                ).format('h:mm A')}`
              : ''}
          </div>
        </div>
      );
      // 3
      tmp.push(driverDTO.phone);
      // 4
      tmp.push(driverDTO?.appInfo?.activationCode);

      if (config.isBackgroundCheckEnabled()) {
        // 5
        tmp.push(
          <div>
            {driverDTO.bgCheck && driverDTO.bgCheck.status ? (
              <span
                className="pending_grey"
                style={{ color: driverDTO.bgCheck?.color }}
              >
                {driverDTO.bgCheck?.status}
              </span>
            ) : (
              ''
            )}
            {driverDTO.bgCheck && driverDTO.bgCheck.email ? (
              <span className="vehicle_detail">{driverDTO.bgCheck?.email}</span>
            ) : (
              ''
            )}
            {driverDTO.bgCheck && driverDTO.bgCheck.amountOwed ? (
              <span className="owed_grey">
                {' '}
                - ${driverDTO.bgCheck.amountOwed} Owed
              </span>
            ) : (
              ''
            )}
          </div>
        ); // Background Check
      }

      // 6
      tmp.push(
        <div className="actions">
          {config.isBackgroundCheckEnabled() &&
            ((driverDTO.bgCheck && driverDTO.bgCheck.bgReqBtnVisible) ||
              !driverDTO.bgCheck) && (
              <a
                className="actions_link"
                data-testid={`bgCheck-${i + 1}`}
                onClick={() => {
                  bgCheckInvitationHandler(driverDTO, i);
                }}
              >
                Request Background Check
              </a>
            )}
          {driverDTO.status !== undefined &&
            (driverDTO.status === 0 || driverDTO.status === 1) && (
              <a
                className="actions_link"
                onClick={() => {
                  changeShiftStatusForDriver(driverDTO);
                }}
              >
                {driverDTO.status === 0 ? 'Start Shift' : 'End Shift'}
              </a>
            )}
          {config.isVehicleManagementEnabled() && (
            <a
              className="actions_link"
              data-testid={`link-vehicle-${i + 1}`}
              onClick={() => {
                openAssignVehicleModal(driverDTO, i);
                AppEvents.trackPage(
                  Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
                    .ON_ASSIGN_VEHICLE_MODAL.id,
                  Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
                    .ON_ASSIGN_VEHICLE_MODAL.name
                );
              }}
            >
              {driverDTO.currentVehicle?.name
                ? 'Reassign Vehicle'
                : 'Assign Vehicle'}
            </a>
          )}
        </div>
      );
      driverArr.push(tmp);
    }
    setDriverDetails({
      data: driverArr,
    });
    return driverDetails;
  };

  const bgCheckInvitationHandler = (
    driverInfo: DriverDTO,
    idx: number
  ): any => {
    const isBgCheckPopupSkipped = localStorage.getItem(
      'bgCheckPopupVisibility'
    );
    if (isBgCheckPopupSkipped && isBgCheckPopupSkipped == 'true') {
      sendBgCheckInvitation(driverInfo, idx);
    } else {
      setBgCheckWarningPopup({
        show: true,
        driver: driverInfo,
        idx,
      });
    }
  };

  function closeBgCheckWarningPopup(): void {
    setBgCheckWarningPopup({
      show: false,
      driver: {} as DriverDTO,
      idx: -1,
    });
  }

  function sendBgCheckInvitation(driverInfo: DriverDTO, index: number): any {
    dispatch({
      type: fleetAction.SEND_BG_CHECK_INVITATION,
      data: {
        driverInfo,
        index,
      },
    });
  }

  const changeShiftStatusForDriver = (driver: DriverDTO): any => {
    let vehiclePayload = {};
    if (driver.status === 0) {
      // 'Start Shift'
      if (
        config.isVehicleManagementEnabled() &&
        driver.currentVehicle &&
        driver.currentVehicle.uKey !== undefined
      ) {
        vehiclePayload = {
          truckUid: driver.currentVehicle.uKey,
          vehicleName: driver.currentVehicle.name,
          payload: {
            location: {
              street: '',
              city: '',
              state: '',
              zip: '',
              latitude: '0.00',
              longitude: '0.00',
            },
            truckInfo: {
              uid: driver.currentVehicle.uKey,
              truckTypeId: driver.currentVehicle.truckTypeId,
            },
            driverInfo: {},
          },
        };
      }
      dispatch({
        type: fleetAction.CHANGE_DRIVER_SHIFT,
        request: fleetAction.START_SHIFT,
        driverPayload: {
          location: {
            street: '',
            city: '',
            state: '',
            zip: '',
            latitude: '0.00',
            longitude: '0.00',
          },
          driverInfo: {
            uid: driver.uid,
            firstName: driver.firstName,
            lastName: driver.lastName,
          },
        },
        vehiclePayload,
      });
    } else {
      if (
        config.isVehicleManagementEnabled() &&
        driver.currentVehicle &&
        driver.currentVehicle.uKey !== undefined
      ) {
        vehiclePayload = {
          truckUid: driver.currentVehicle.uKey,
          vehicleName: driver.currentVehicle.name,
          payload: { truckInfo: { id: driver.currentVehicle.truckTypeId } },
        };
      }
      // 'End Shift'
      dispatch({
        type: fleetAction.CHANGE_DRIVER_SHIFT,
        request: fleetAction.END_SHIFT,
        driverPayload: {
          driverInfo: { uid: driver.uid },
        },
        vehiclePayload,
      });
    }
  };

  const openAssignVehicleModal = (driver: DriverDTO, index: number): void => {
    // console.log('Hello from openAssignVehicleModal: ', driver);
    setAssignVehicleModal({
      show: true,
      driver,
      assignType: 'VEHICLE',
      idx: index,
    });
  };

  const linkDriverVehicle = (data: any): void => {
    dispatch({
      type: fleetAction.LINK_DRIVER_VEHICLE,
      data,
    });
    setAssignVehicleModal({
      show: false,
      assignType: '',
      driver: {} as DriverDTO,
      idx: -1,
    });
  };

  const deleteAction = (): any => {
    dispatch({
      type: fleetAction.DELETE_DRIVER,
      driver: showDeleteScreen.driver,
    });

    setDeleteScreen({
      name: '',
      show: false,
      driver: {} as DriverDTO,
    });

    setViewModal({
      show: false,
      driver: {} as DriverDTO,
      idx: -1,
    });
  };

  const saveChanges = (dataObj: any): any => {
    const driverObj = dataObj;
    const data: any = {
      driverInfo: {
        firstName: driverObj.firstName,
        lastName: driverObj.lastName,
        phoneNumber: driverObj.phoneNumber
          ? driverObj.phoneNumber.match(/\d+/g).join('')
          : '',
        externalId: driverObj.externalId,
        // picture: driverObj.picture,
        file: driverObj.file,
      },
    };
    const date = new Date().getTime();
    // PN-1021
    data.driverInfo.externalId =
      driverObj.firstName.charAt(0).toUpperCase() +
      driverObj.lastName.charAt(0).toUpperCase() +
      '_' +
      data.driverInfo.phoneNumber +
      '_' +
      date; // TODO
    if (showAddEditModal.operation === 'edit') {
      data.driverInfo.id =
        fleetData.driverList[showAddEditModal.data.index].uid;
      data.driverInfo.companyId = providerData.providerId;
      data.appInfo = {
        device:
          fleetData.driverList[showAddEditModal.data.index].appInfo.device,
        os: fleetData.driverList[showAddEditModal.data.index].appInfo.os,
        version:
          fleetData.driverList[showAddEditModal.data.index].appInfo.version,
      };
      dispatch({ type: fleetAction.UPDATE_DRIVER, data });
    } else {
      const browser: any = Bowser.getParser(window.navigator.userAgent);
      data.appInfo = {
        device: browser?.parsedResult?.browser?.name,
        os: browser?.parsedResult?.os?.name,
        version: browser?.parsedResult?.browser?.version,
      };
      data.driverInfo.providerUid = providerData.providerUid;
      dispatch({
        type: fleetAction.ADD_DRIVER,
        data,
        isBGCheckRequested: dataObj.isBGCheckRequested,
      });
    }
    setAddEditModal({
      ...showAddEditModal,
      data: addEditModalData,
      show: false,
    });
    setViewModal({
      show: false,
      driver: {} as DriverDTO,
      idx: -1,
    });
  };

  const openAddEditModal = (
    modalType: string,
    operation: string,
    show: boolean,
    data?: any
  ): any => {
    setAddEditModal({
      ...showAddEditModal,
      modalType,
      operation,
      show,
      data,
    });
  };

  const openEditTechnicianModal = (driver: DriverDTO, index: number): void => {
    openAddEditModal('driver', 'edit', true, {
      picture: driver?.profileInfo?.picture,
      firstName: driver?.firstName,
      lastName: driver?.lastName,
      externalId: driver?.externalId,
      phoneNumber: driver?.phone ? formatFullPhoneNumber(driver?.phone) : '',
      file: {},
      index,
    });
    AppEvents.trackPage(
      Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
        .ON_FLEET_ASSETS_DRIVER_EDIT_MODAL.id,
      Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
        .ON_FLEET_ASSETS_DRIVER_EDIT_MODAL.name
    );
  };

  const confirmDeleteTechnician = (driver: DriverDTO): void => {
    setDeleteScreen({
      show: true,
      name: `${driver.firstName} ${driver.lastName}`,
      driver,
    });
  };

  return (
    <Container fluid>
      <Row>
        <div className="tab_wrap pl15 pr15 pt25">
          <Row>
            <Col xs={8}>
              <h3 className="sub_title">DRIVERS</h3>
              <p className="sub_title_info">Add and manage your drivers.</p>
            </Col>
            {providerData.authToken && (
              <Col sm={4}>
                <Button
                  variant="basic"
                  data-testid="add-driver"
                  clx="button_secondary black float-right"
                  onClick={() => {
                    setAddEditModal({
                      modalType: 'driver',
                      operation: 'add',
                      show: true,
                      data: addEditModalData,
                    });
                    AppEvents.trackPage(
                      Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
                        .ON_FLEET_ASSETS_DRIVER_ADD_MODAL.id,
                      Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
                        .ON_FLEET_ASSETS_DRIVER_ADD_MODAL.name
                    );
                  }}
                >
                  Add Driver
                </Button>
              </Col>
            )}
          </Row>
          <div className="settings_table showScroll">
            <Table
              tableName="Driver Table"
              clx="round_border"
              data={driverDetails.data}
              renderHeader={RenderHeader}
              renderItem={RenderItem}
              noDataFound={NoDataFound}
              addEditModal={openAddEditModal}
            />
          </div>
        </div>
      </Row>

      <AddEditModal
        show={showAddEditModal.show}
        handleClose={() =>
          setAddEditModal({
            ...showAddEditModal,
            data: addEditModalData,
            show: false,
          })
        }
        modalType={showAddEditModal.modalType}
        operation={showAddEditModal.operation}
        data={showAddEditModal.data}
        saveChanges={saveChanges}
      />

      <DeleteScreen
        show={showDeleteScreen.show}
        name={showDeleteScreen.name}
        handleClose={() =>
          setDeleteScreen({
            name: '',
            show: false,
            driver: {} as DriverDTO,
          })
        }
        deleteAction={deleteAction}
      />

      <ViewTechnicianModal
        show={showViewModal.show}
        driver={showViewModal.driver}
        index={showViewModal.idx}
        handleClose={() => {
          setViewModal({
            show: false,
            driver: {} as DriverDTO,
            idx: -1,
          });
        }}
        editTechnician={openEditTechnicianModal}
        deleteTechnician={confirmDeleteTechnician}
      />

      <AssignDriverVehicleModal
        show={showAssignVehicleModal.show}
        driverOrVehicle={showAssignVehicleModal.driver}
        index={showAssignVehicleModal.idx}
        assignType={showAssignVehicleModal.assignType}
        handleClose={() => {
          setAssignVehicleModal({
            show: false,
            assignType: '',
            driver: {} as DriverDTO,
            idx: -1,
          });
        }}
        linkDriverVehicle={linkDriverVehicle}
      />

      <BgCheckWarningPopup
        show={bgCheckWarningPopup.show}
        handleClose={() => {
          closeBgCheckWarningPopup();
        }}
        sendBgCheckInvitation={() => {
          const { driver, idx } = bgCheckWarningPopup;
          sendBgCheckInvitation(driver, idx);
          closeBgCheckWarningPopup();
        }}
      />
    </Container>
  );
};

export default TechnicianListing;
