import axios from 'axios';
import { config as CONFIG } from './config';
import { POST_UPLOAD_DOCUMENT_API } from '../constants/API';

export function multipartPostUpload(
  url: string,
  data: any,
  isAuthHeader: boolean,
  header: any,
  coi?: boolean,
  isVehicle?: boolean
): any {
  const authToken = CONFIG.AUTH_TOKEN();

  if (header) {
    header = {
      ...header,
      'auth-token': authToken,
      'Content-Type': undefined,
    };
    header = {
      ...header,
      Product: CONFIG.EVENT_HEADER,
    };
  } else if (isAuthHeader) {
    header = {
      'auth-token': authToken,
      'Content-Type': undefined,
      Product: CONFIG.EVENT_HEADER,
    };
  }
  const config = {
    headers: header,
  };
  const json = JSON.stringify(data.model);
  const payload = new Blob([json], { type: 'application/json' });
  const transformRequest = new FormData();
  if (coi) {
    transformRequest.append('coi', payload);
    transformRequest.append('file', data.files);
  } else if (isVehicle) {
    transformRequest.append('photo', data);
    transformRequest.append('meta', payload);
  } else {
    transformRequest.append('meta', payload);
    transformRequest.append('file', data.files);
  }

  return axios({
    method: 'post',
    url: url,
    headers: config.headers,
    data: transformRequest,
  });
}

export function uploadFile(
  file: any,
  caseId: number,
  name: string,
  notes: string,
  jobId?: any
): any {
  const payload: any = {
    caseId,
    name,
    notes,
  };
  if (jobId) {
    payload.jobId = jobId;
  }
  const data = { model: payload, files: file };

  return multipartPostUpload(POST_UPLOAD_DOCUMENT_API, data, true, false);
}
