import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';

export interface Props {
  isChosen: boolean;
}

export const Toggler: React.FC<Props> = (props) => {
  return (
    <td className="p-3 action">
      <FontAwesomeIcon
        icon={props.isChosen ? faMinusSquare : faPlusSquare}
        size="1x"
        title={props.isChosen ? 'minus-icon' : 'plus-icon'}
      />
    </td>
  );
};
