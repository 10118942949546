// Authentication Actions
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

// Authentication Actions
export const ANGULAR_AUTH_SUCCESS = 'ANGULAR_AUTH_SUCCESS';
export const UPDATE_VEHICLE_MANAGEMENT_FLAG = 'UPDATE_VEHICLE_MANAGEMENT_FLAG';

// clear store Action
export const CLEAR_STORE = 'CLEAR_STORE';
