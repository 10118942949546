import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { formatFullPhoneNumber } from '../../utils/commonUtility';
import { CustomModal, Button } from '../../urgently-component';
import '../technicians/technicians.scss';
import { DriverDTO } from '../../types/reducerTypes';
import * as fleetAction from '../../saga/fleet/fleetActionTypes';

interface Props {
  show: boolean;
  handleClose: () => void;
  driver: DriverDTO;
  index: number;
  editTechnician: (driver: DriverDTO, index: number) => void;
  deleteTechnician: (driver: DriverDTO) => void;
}

export const ViewTechnicianModal: React.FC<Props> = (props: Props) => {
  const { index, driver, editTechnician, deleteTechnician } = props;

  const dispatch = useDispatch();
  const getAppInfo = (driverInfo: DriverDTO): string => {
    let appInfo = 'N/A';
    if (driverInfo) {
      const device: string = driverInfo.appInfo?.device;
      const os: string = driverInfo.appInfo?.os;
      const version: string = driverInfo.appInfo?.appVersion;
      const deviceToken: string = driverInfo.appInfo?.deviceToken;
      if (deviceToken) {
        appInfo = device;
        if (os) {
          appInfo = appInfo + ' ' + os;
        }
        if (version) {
          appInfo = appInfo + ' (V ' + version + ')';
        }
      }
      if (!deviceToken) {
        appInfo = 'N/A';
      }
    }
    return appInfo;
  };
  const regenerateActivationCode = (driverId: string): void => {
    dispatch({ type: fleetAction.REGENERATE_ACTIVATION_CODE, driverId });
  };

  const sendTextLink = (phone: string): void => {
    dispatch({ type: fleetAction.SEND_TEXT_LINK, phone });
  };

  return (
    <CustomModal
      show={props.show}
      closeModal={props.handleClose}
      title="Driver"
      actionButtons={
        <>
          <Button
            variant="basic"
            data-testid="btn-edit"
            clx="button_secondary black btn_gap"
            onClick={editTechnician.bind(this, driver, index)}
          >
            Edit
          </Button>
          <Button
            variant="basic"
            data-testid="btn-delete"
            clx="button_danger"
            onClick={deleteTechnician.bind(this, driver)}
          >
            Delete
          </Button>
        </>
      }
    >
      {/* 
      // TODO - fix this UI
      <div className="delete_overlay">
        <div className="pop_overlay dark" />
        <div className="pop_box">
          <div className="title text_capital">Delete driver?</div>
          <div className="sub_title">
            This will remove your ability to assign {driver.firstName}{' '}
            {driver.lastName} to jobs.
          </div>
          <form className="form">
            <div className="form-group noGutter d-flex content-center mt15">
              <button
                type="button"
                className="btns min_width_170 btn_outline_black mr15"
                onClick={props.handleClose}
              >
                No, Don’t Delete
              </button>
              <button type="button" className="btns btn_red min_width_170">
                Yes, Delete
              </button>
            </div>
          </form>
          <div className="note">
            <p>This action can not be undone.</p>
          </div>
        </div>
      </div> */}
      <Form className="custom_form">
        <Row>
          <Col xs={4}>
            <div className="profile_img mt10">
              <div className="img_box edit">
                {driver?.profileInfo?.picture ? (
                  <div className="tab_mask">
                    <img
                      className="icon"
                      alt="img"
                      src={driver?.profileInfo?.picture}
                    />
                  </div>
                ) : (
                  <div className="user_mask_view">
                    {driver?.firstName?.charAt(0) || ''}
                    {driver?.lastName?.charAt(0) || ''}
                  </div>
                )}
              </div>
            </div>

            <div
              className={`driver_shift_text ${
                driver.status === 1 ? 'on_shift' : ''
              }`}
            >
              {driver.status === 0
                ? 'Off Shift'
                : driver.status === 1
                ? 'On Shift'
                : 'Busy'}
            </div>
          </Col>

          <Col xs={8} className="pl0">
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <p className="form_control_data mb10">
                {driver?.firstName || ''} {driver?.lastName || ''}
              </p>

              <Form.Label>Phone Number</Form.Label>
              <p className="form_control_data mb10">
                {driver?.phone ? formatFullPhoneNumber(driver.phone) : ''}
              </p>

              <Form.Label htmlFor="name">Activation Code</Form.Label>
              <p className="form_control_data mb10">
                {driver?.appInfo?.activationCode || ''}
                <br />
                <Button
                  variant="basic"
                  clx="btn button_black mt10"
                  data-testid="regenerate-activation-code"
                  onClick={() => regenerateActivationCode(driver.uid)}
                >
                  REGENERATE
                </Button>
              </p>

              <Form.Label htmlFor="name">App Info</Form.Label>
              <p className="form_control_data">
                {getAppInfo(driver)}
                <br />
                <Button
                  variant="basic"
                  clx="btn button_black mt10"
                  data-testid="send-text-link"
                  onClick={() => sendTextLink(driver.phone)}
                >
                  TEXT LINK
                </Button>
              </p>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default ViewTechnicianModal;
