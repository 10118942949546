import React from 'react';
import { Modal } from 'react-bootstrap';

import './modal.scss';

export interface Props {
  title: string;
  show: boolean;
  closeModal?: () => void;
  actionButtons?: JSX.Element;
  size?: 'small' | 'large';
  dialogClassName?: string;
  noCloseButton?: boolean;
}

export const CustomModal: React.FC<Props> = React.memo((props) => {
  const {
    title,
    show,
    closeModal,
    children,
    actionButtons,
    size,
    dialogClassName,
    noCloseButton,
  } = props;
  const noHide = React.useCallback(() => '', []);
  return (
    <Modal
      show={show}
      onHide={closeModal ?? noHide}
      className={`custom_modal ${size ? `custom_modal_${size}` : ''}`}
      dialogClassName={dialogClassName}
      animation={false}
    >
      <Modal.Header closeButton={!noCloseButton}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      {actionButtons && <Modal.Footer>{actionButtons}</Modal.Footer>}
    </Modal>
  );
});
