import React from 'react';

import { HashRouter, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  AuthInitialStateTypes,
  ConfigInitialStateTypes,
} from './types/reducerTypes';
import { routes } from './constants/utilConstants';
import { Loader } from './urgently-component';
import FleetAssets from './components/fleetAssets/fleetAssets';
import Notifications from './containers/notificationsContainer';
import { ErrorBoundary } from '@sentry/react';

interface Props {
  config: ConfigInitialStateTypes;
  authentication: AuthInitialStateTypes;
}

const App: React.FC<Props> = (props: Props) => {
  const { config, authentication } = props;

  return (
    <div data-testid="app-component-container">
      <Loader show={config.isLoading} />
      <HashRouter>
        <Route
          path={routes.FLEETS}
          exact
          render={() => (
            <ErrorBoundary>
              <FleetAssets />
            </ErrorBoundary>
          )}
        />
        <Redirect to={routes.FLEETS} />
      </HashRouter>

      <ErrorBoundary>
        <Notifications />
      </ErrorBoundary>
    </div>
  );
};

const mapStateToProps = (props: Props): Props => {
  return {
    config: props.config,
    authentication: props.authentication,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
  return { dispatch };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
