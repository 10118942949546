export const HOSTURL = process.env.REACT_APP_API_HOST;
export const MICRO_SERVICE_HOSTURL =
  process.env.REACT_APP_MICRO_SERVICE_API_HOST;
export const HTTP_POST = `POST`;
export const HTTP_PUT = `PUT`;
export const HTTP_GET = `GET`;
export const HTTP_DELETE = `DELETE`;

export const LOGIN_NEW = `${HOSTURL}/ops/login-new`;
export const FLEET_DRIVER_LIST = (providerUid: string): string =>
  `${HOSTURL}/ops/provider-drivers?providerUid=${providerUid}&page=0&size=100`;
export const FLEET_DRIVER_UPDATE = (providerUid: string): string =>
  `${HOSTURL}/ops/provider-drivers/${providerUid}`;
export const FLEET_VEHICLE_LIST = (providerId: string): string =>
  `${HOSTURL}/ops/provider-vehicles/get-vehicles?providerId=${providerId}&offset=0&limit=100`;
export const TRUCK_TYPES = `${HOSTURL}/ops/configs/truck/types`;
export const ADD_TECHNICIAN = `${HOSTURL}/ops/provider-drivers/create-technician`;
export const FLEET_VEHICLE_UPDATE = (providerUid: string): string =>
  `${HOSTURL}/ops/provider-vehicles/${providerUid}`;
export const ADD_PROVIDER_VEHICLE = `${HOSTURL}/ops/provider-vehicles/create-vehicle`;

export const DRIVER_CHECK_IN = (driverUid: string): string =>
  `${HOSTURL}/ops/provider-drivers/technician-checkin?technicianUid=${driverUid}`;
export const DRIVER_CHECK_OUT = (driverUid: string): string =>
  `${HOSTURL}/ops/provider-drivers/technician-checkout?technicianUid=${driverUid}`;

export const VEHICLE_CHECK_IN = (vehicleUid: string): string =>
  HOSTURL + '/ops/provider-vehicles/checkin?vehicleUid=' + vehicleUid;
export const VEHICLE_CHECK_OUT = (vehicleUid: string): string =>
  HOSTURL + '/ops/provider-vehicles/checkout?vehicleUid=' + vehicleUid;

export const LINK_TRUCK_DRIVER = (
  driverUid: string,
  vehicleUid: string
): string =>
  `${HOSTURL}/ops/provider-drivers/assign-to-vehicle?vehicleUid=${vehicleUid}&technicianUid=${driverUid}`;

export const POST_UPLOAD_DOCUMENT_API = `${HOSTURL}/ops/documents`;

export const SERVICE_CATEGORIES = (userId: number): string =>
  `${HOSTURL}/ops/providers/${userId}/categories`;
export const UPDATE_SERVICES = (userId: number, uKey: string): string =>
  `${HOSTURL}/ops/providers/${userId}/drivers/${uKey}/services`;

export const DRIVER_ACTIVATION_CODE = (driverId: string): string =>
  `${HOSTURL}/ops/provider-drivers/activation-code?driverId=${driverId}`;
export const TEXT_APP_LINK = (
  userId: number,
  driverPhone: string,
  os: string
): string =>
  `${HOSTURL}/ops/providers/${userId}/text-app-link?to=${driverPhone}&os=${os}`;

export const BUSINESS_INFO = (userId: number): string =>
  `${HOSTURL}/ops/providers/${userId}/provider-profile`;

export const BG_CHECK_INVITE = `${MICRO_SERVICE_HOSTURL}background-verification-service-v1/backgroundcheck/invite`;
export const UNSUBSCRIBE_CONTINUOUS_CHECK = `${MICRO_SERVICE_HOSTURL}background-verification-service-v1/continuouscheck/unsubscribe/driver`;
export const DRIVER_BG_CHECK_STATUS_LIST = (providerUid: string): string =>
  `${MICRO_SERVICE_HOSTURL}background-verification-service-v1/backgroundcheck/${providerUid}/drivers`;
export const SINGLE_DRIVER_STATUS = (
  providerUid: string,
  driverUid: string
): string =>
  `${MICRO_SERVICE_HOSTURL}background-verification-service-v1/backgroundcheck/${providerUid}/${driverUid}`;

export const TRACK_DISPATCH_EVENTS = `${HOSTURL}/ops/events/dispatch-events`;
