import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  ADD_DRIVER,
  ADD_VEHICLE,
  CHANGE_DRIVER_SHIFT,
  CHANGE_DRIVER_SHIFT_SUCCESS,
  CHANGE_VEHICLE_SHIFT,
  CHANGE_VEHICLE_SHIFT_SUCCESS,
  DELETE_DRIVER,
  DELETE_VEHICLE,
  GET_DRIVERS_BG_CHECK_LIST,
  GET_FLEET_DRIVER_LIST,
  GET_FLEET_DRIVER_LIST_SUCCESS,
  GET_FLEET_VEHICLE_LIST,
  GET_FLEET_VEHICLE_LIST_SUCCESS,
  GET_SERVICE_CATEGORIES,
  GET_SERVICE_CATEGORIES_SUCCESS,
  GET_TRUCK_TYPES,
  GET_TRUCK_TYPES_SUCCESS,
  LINK_DRIVER_VEHICLE,
  REGENERATE_ACTIVATION_CODE,
  REGENERATE_ACTIVATION_CODE_SUCCESS,
  SEND_BG_CHECK_INVITATION,
  SEND_TEXT_LINK,
  UPDATE_DRIVER,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_DTO,
  BUSINESS_INFO_REQUEST,
  BUSINESS_INFO_SUCCESS,
  START_SHIFT,
  END_SHIFT,
} from './fleetActionTypes';
import { HIDE_LOADING, SHOW_LOADING } from '../config/configActionTypes';
import API from '../../utils/API';
import {
  FLEET_DRIVER_LIST,
  FLEET_DRIVER_UPDATE,
  FLEET_VEHICLE_LIST,
  HTTP_DELETE,
  HTTP_GET,
  HTTP_PUT,
  HTTP_POST,
  TRUCK_TYPES,
  ADD_TECHNICIAN,
  FLEET_VEHICLE_UPDATE,
  ADD_PROVIDER_VEHICLE,
  DRIVER_ACTIVATION_CODE,
  TEXT_APP_LINK,
  SERVICE_CATEGORIES,
  LINK_TRUCK_DRIVER,
  DRIVER_CHECK_IN,
  DRIVER_CHECK_OUT,
  VEHICLE_CHECK_IN,
  VEHICLE_CHECK_OUT,
  UPDATE_SERVICES,
  BG_CHECK_INVITE,
  DRIVER_BG_CHECK_STATUS_LIST,
  BUSINESS_INFO,
  UNSUBSCRIBE_CONTINUOUS_CHECK,
} from '../../constants/API';
import { getAuthData } from '../login/loginSelectors';
import { multipartPostUpload } from '../../utils/uploadDocuments';
import {
  AuthTypes,
  BGCheckStatusDTO,
  BusinessInfo,
  DriverBGCheckDTO,
  DriverDTO,
  VehicleDTO,
} from '../../types/reducerTypes';
import { SHOW_NOTIFICATION } from '../notifications/notificationsActionTypes';
import { bgStatusList } from '../../utils/commonUtility';
import { getFleetData } from './fleetSelector';
import { AppEvents } from '../../constants/analytics';
import { Events } from '../../constants/appEvents';
import { UPDATE_VEHICLE_MANAGEMENT_FLAG } from '../login/loginActionTypes';

let file: any = {};

export function* getTruckTypes(): any {
  try {
    const response = yield call(API, HTTP_GET, TRUCK_TYPES, null, true);
    if (response?.data) {
      yield put({
        type: GET_TRUCK_TYPES_SUCCESS,
        response: response.data,
      });
    }
  } catch (error) {
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* getServiceCategories(): any {
  try {
    const providerData = yield select(getAuthData);
    const response = yield call(
      API,
      HTTP_GET,
      SERVICE_CATEGORIES(providerData.id),
      null,
      true
    );
    if (response?.data) {
      const dispatchServices = [...response.data];
      dispatchServices.forEach(function (service: any, idx: number) {
        if (service.id === 2009) {
          // remove - 'Dealer Tire Service'
          dispatchServices.splice(idx, 1);
        }
      });
      yield put({
        type: GET_SERVICE_CATEGORIES_SUCCESS,
        response: dispatchServices,
      });
    }
  } catch (error) {
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* businessInfoRequest(): any {
  try {
    const providerData = yield select(getAuthData);
    const response = yield call(
      API,
      HTTP_GET,
      BUSINESS_INFO(providerData.id),
      null,
      true
    );
    if (response?.data?.data && response.data.data.length > 0) {
      yield put({
        type: BUSINESS_INFO_SUCCESS,
        response: response.data.data[0],
      });
    }
  } catch (error) {
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* getDriversBGStatusList(data: any): any {
  try {
    const providerData: AuthTypes = yield select(getAuthData);
    const response = yield call(
      API,
      HTTP_GET,
      DRIVER_BG_CHECK_STATUS_LIST(providerData.providerUid),
      null,
      true,
      true
    );
    if (response?.data && response.data.length > 0) {
      const driverList: DriverDTO[] = [...data.driverList];
      const driverBGCheckStatusList: DriverBGCheckDTO[] = response.data;
      for (const i in driverList) {
        // Adding BackGround check details
        for (const j in driverBGCheckStatusList) {
          if (
            driverList[i].uid &&
            driverBGCheckStatusList[j].data.driver_uid === driverList[i].uid
          ) {
            driverList[i].bgCheck = {} as BGCheckStatusDTO;
            const driverBGCheckStatus = driverBGCheckStatusList[j].data;
            const bgStatusObj = bgStatusList().find((bgStatus: any) => {
              if (driverBGCheckStatus?.adjudication) {
                return (
                  bgStatus.key.toLowerCase() ===
                  driverBGCheckStatus.adjudication.toLowerCase()
                );
              } else {
                return (
                  bgStatus.key.toLowerCase() ===
                  driverBGCheckStatus.status.toLowerCase()
                );
              }
            });
            if (bgStatusObj) {
              driverList[i].bgCheck = {
                status: bgStatusObj.value,
                color: bgStatusObj.color,
                bgReqBtnVisible: bgStatusObj.bgReqBtn,
                amountOwed: driverBGCheckStatus.price,
                email: driverBGCheckStatus.email,
              };
            }
            break;
          }
        }
      }
      yield put({
        type: GET_FLEET_DRIVER_LIST_SUCCESS,
        response: driverList,
      });
    }
  } catch (error) {
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* getDriverList(): any {
  try {
    // yield put({ type: SHOW_LOADING });
    const providerData: AuthTypes = yield select(getAuthData);
    const response = yield call(
      API,
      HTTP_GET,
      FLEET_DRIVER_LIST(providerData.providerUid),
      null,
      true
    );
    // yield put({ type: HIDE_LOADING });
    if (response?.data) {
      const driverList: DriverDTO[] = [];
      for (const i in response.data) {
        const driver: DriverDTO = response.data[i];
        if (!driver.firstName) driver.firstName = '';
        if (!driver.lastName) driver.lastName = '';
        driverList.push(driver);
      }
      yield put({
        type: GET_FLEET_DRIVER_LIST_SUCCESS,
        response: driverList,
      });
      if (driverList.length > 0) {
        yield call(getDriversBGStatusList, { driverList });
      }
    }
  } catch (error) {
    // yield put({ type: HIDE_LOADING });
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* updateDriver(data: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    file = data.data.driverInfo.file;
    delete data.data.driverInfo.file;
    const response = yield call(
      API,
      HTTP_PUT,
      FLEET_DRIVER_UPDATE(data.data.driverInfo.id),
      data.data,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      AppEvents.trackEvent(
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
          .ON_FLEET_ASSETS_DRIVER_EDIT_MODAL.Actions.DRIVER_EDITED.id,
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
          .ON_FLEET_ASSETS_DRIVER_EDIT_MODAL.Actions.DRIVER_EDITED.name,
        null,
        {
          uid: data.data.driverInfo.id,
          name: `${data.data.driverInfo.firstName} ${data.data.driverInfo.lastName}`,
        }
      );
      if (file && file.name) {
        yield call(uploadProfilePic, 'driver', data.data.driverInfo.id);
      }
      yield call(getDriverList);
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'success',
          text: 'Changes Saved! This driver has been updated.',
        },
      });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.error,
        },
      });
    }
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* addDriver(data: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    file = data.data.driverInfo.file;
    delete data.data.driverInfo.file;
    const response = yield call(
      API,
      HTTP_POST,
      ADD_TECHNICIAN,
      data.data,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      AppEvents.trackEvent(
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
          .ON_FLEET_ASSETS_DRIVER_ADD_MODAL.Actions.DRIVER_ADDED.id,
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
          .ON_FLEET_ASSETS_DRIVER_ADD_MODAL.Actions.DRIVER_ADDED.name,
        null,
        {
          uid: response.data?.data[0]?.driverInfo?.uid,
          name: `${data.data.driverInfo.firstName} ${data.data.driverInfo.lastName}`,
        }
      );
      if (file && file.name) {
        yield call(
          uploadProfilePic,
          'driver',
          response.data.data[0].driverInfo.uid
        );
      }
      if (data.isBGCheckRequested) {
        yield call(sendBgCheckInvitation, {
          data: {
            driverInfo: response.data.data[0].driverInfo,
          },
        });
      }
      yield call(getDriverList);
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'success',
          text: 'Driver Added! This driver has been added to your fleet.',
        },
      });
    }
  } catch (error) {
    console.log(error.toString());
    yield put({ type: HIDE_LOADING });
    if (error && error.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.error,
        },
      });
    }
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* unsubscribeContinuousCheck(data: any): any {
  try {
    const driver: DriverDTO = data.driver;
    const providerData: AuthTypes = yield select(getAuthData);
    const formData = {
      provideruid: providerData.providerUid,
      driveruid: driver.uid,
    };
    const response = yield call(
      API,
      HTTP_POST,
      UNSUBSCRIBE_CONTINUOUS_CHECK,
      formData,
      true,
      true
    );
    if (response?.status === 200) {
      if (response.data?.errorResponseDTO?.message) {
        yield put({
          type: SHOW_NOTIFICATION,
          payload: {
            type: 'error',
            text: response.data?.errorResponseDTO?.message,
          },
        });
      } else {
        console.log('BG Check UNSUBSCRIBED!!');
      }
    }
  } catch (error) {
    console.log(error.toString());
  }
}

export function* deleteDriver(data: any): any {
  try {
    const driver: DriverDTO = data.driver;
    const businessInfo: BusinessInfo = (yield select(getFleetData))
      .businessInfo;
    yield put({ type: SHOW_LOADING });
    const response = yield call(
      API,
      HTTP_DELETE,
      FLEET_DRIVER_UPDATE(driver.uid),
      {},
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.status === 200) {
      AppEvents.trackEvent(
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
          .ON_FLEET_ASSETS_DRIVER_VIEW_MODAL.Actions.DRIVER_DELETED.id,
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
          .ON_FLEET_ASSETS_DRIVER_VIEW_MODAL.Actions.DRIVER_DELETED.name,
        null,
        { uid: driver.uid }
      );
      if (
        businessInfo.backgroundCheckEnabled &&
        driver?.bgCheck &&
        driver.bgCheck.status &&
        driver.bgCheck.status !== 'Request Sent'
      ) {
        yield call(unsubscribeContinuousCheck, data);
      }
      yield call(getDriverList);
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'success',
          text: 'Changes Saved! This driver has been deleted.',
        },
      });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* regenerateActivationCode(data: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(
      API,
      HTTP_GET,
      DRIVER_ACTIVATION_CODE(data.driverId),
      null,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      AppEvents.trackEvent(
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
          .ON_FLEET_ASSETS_DRIVER_VIEW_MODAL.Actions.ACTIVATION_CODE_REGENERATED
          .id,
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
          .ON_FLEET_ASSETS_DRIVER_VIEW_MODAL.Actions.ACTIVATION_CODE_REGENERATED
          .name,
        null,
        { code: response.data.activationCode }
      );
      yield put({
        type: REGENERATE_ACTIVATION_CODE_SUCCESS,
        response: response.data,
      });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* sendTextLink(data: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const userData: AuthTypes = yield select(getAuthData);
    const response = yield call(
      API,
      HTTP_POST,
      TEXT_APP_LINK(userData.id, data.phone, ''),
      null,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      AppEvents.trackEvent(
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
          .ON_FLEET_ASSETS_DRIVER_VIEW_MODAL.Actions.TEXT_LINK_SENT.id,
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages
          .ON_FLEET_ASSETS_DRIVER_VIEW_MODAL.Actions.TEXT_LINK_SENT.name,
        null,
        { phoneNo: data.phone }
      );
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'success',
          text: 'The link to download the application has been sent.',
        },
      });
    } else {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: 'We are not able to send the link to the application at this time.',
        },
      });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* getVehicleList(): any {
  try {
    // yield put({ type: SHOW_LOADING });
    const providerData = yield select(getAuthData);
    const response = yield call(
      API,
      HTTP_GET,
      FLEET_VEHICLE_LIST(providerData.providerId),
      null,
      true
    );
    // yield put({ type: HIDE_LOADING });
    if (response?.data?.data) {
      const vehicleList: VehicleDTO[] = [];
      for (const i in response.data.data) {
        const vehicle: VehicleDTO = response.data.data[i];
        let splitTruck = [];
        if (vehicle.truckInfo?.name) {
          splitTruck = vehicle.truckInfo.name?.split(' ');
          if (splitTruck.length >= 2) {
            vehicle.truckInfo.truckInitial =
              splitTruck[0].charAt(0) + splitTruck[1].charAt(0);
          } else {
            vehicle.truckInfo.truckInitial = splitTruck[0].charAt(0);
          }
        } else {
          vehicle.truckInfo.name = '';
          vehicle.truckInfo.truckInitial = '';
        }
        vehicleList.push(vehicle);
      }

      yield put({
        type: GET_FLEET_VEHICLE_LIST_SUCCESS,
        response: vehicleList,
      });
    }
  } catch (error) {
    // yield put({ type: HIDE_LOADING });
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* addVehicle(data: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    file = data.data.truckInfo.file;
    delete data.data.truckInfo.file;
    const response = yield call(
      API,
      HTTP_POST,
      ADD_PROVIDER_VEHICLE,
      data.data,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      AppEvents.trackEvent(
        Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
          .ON_FLEET_ASSETS_VEHICLE_ADD_MODAL.Actions.VEHICLE_ADDED.id,
        Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
          .ON_FLEET_ASSETS_VEHICLE_ADD_MODAL.Actions.VEHICLE_ADDED.name,
        null,
        {
          uid: data.data[0]?.truckInfo?.uid,
          name: data.data[0]?.truckInfo?.name,
        }
      );
      if (file && file.name) {
        yield call(
          uploadProfilePic,
          'vehicle',
          response.data.data[0]?.truckInfo?.uid
        );
      }
      yield call(getVehicleList);
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
      yield call(vehicleManagementHandler, error.response.data);
    }
  }
}

export function* deleteVehicle(data: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(
      API,
      HTTP_DELETE,
      FLEET_VEHICLE_UPDATE(data.data.uid),
      {},
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.status === 200) {
      // yield call(getVehicleList);
      AppEvents.trackEvent(
        Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
          .ON_FLEET_ASSETS_VEHICLE_VIEW_MODAL.Actions.VEHICLE_DELETED.id,
        Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
          .ON_FLEET_ASSETS_VEHICLE_VIEW_MODAL.Actions.VEHICLE_DELETED.name,
        null,
        { uid: data.data.uid, name: data.data.name }
      );

      const vehicleList: VehicleDTO[] = (yield select(getFleetData))
        .vehicleList;
      const idx: number = data.data.idx;
      vehicleList.splice(idx, 1);
      yield put({
        type: GET_FLEET_VEHICLE_LIST_SUCCESS,
        response: [...vehicleList],
      });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
      yield call(vehicleManagementHandler, error.response.data);
    }
  }
}

export function* updateServiceCategories(data: any): any {
  try {
    const userData: AuthTypes = yield select(getAuthData);
    const response = yield call(
      API,
      HTTP_PUT,
      UPDATE_SERVICES(userData.id, data.truckInfo.uid),
      data.payload,
      true
    );
    if (response?.status === 200) {
      yield put({
        type: UPDATE_VEHICLE_DTO,
        data,
      });
    }
  } catch (error) {
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* updateVehicle(data: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    file = data.data.truckInfo.file;
    delete data.data.truckInfo.file;
    const updatedServices = [...data.data.truckInfo.services];
    delete data.data.truckInfo.services;
    const response = yield call(
      API,
      HTTP_PUT,
      FLEET_VEHICLE_UPDATE(data.data.truckInfo.uid),
      data.data,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      if (file && file.name) {
        yield call(uploadProfilePic, 'vehicle', data.data.truckInfo.uid);
      }
      yield call(getVehicleList);
      yield call(updateServiceCategories, {
        truckInfo: data.data.truckInfo,
        payload: { services: updatedServices },
      });

      AppEvents.trackEvent(
        Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
          .ON_FLEET_ASSETS_VEHICLE_EDIT_MODAL.Actions.VEHICLE_EDITED.id,
        Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages
          .ON_FLEET_ASSETS_VEHICLE_EDIT_MODAL.Actions.VEHICLE_EDITED.name,
        null,
        {
          uid: data.data.truckInfo.uid,
          name: response?.data?.data[0]?.truckInfo?.name,
        }
      );

      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'success',
          text: 'Changes Saved! This vehicle has been updated.',
        },
      });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
      yield call(vehicleManagementHandler, error.response.data);
    }
  }
}

export function* uploadProfilePic(modalType: string, uid: string): any {
  try {
    yield put({ type: SHOW_LOADING });
    let response;
    if (modalType === 'driver') {
      response = yield call(
        multipartPostUpload,
        FLEET_DRIVER_UPDATE(uid) + '/photo',
        file,
        true,
        false,
        false,
        true
      );
    } else {
      response = yield call(
        multipartPostUpload,
        FLEET_VEHICLE_UPDATE(uid) + '/photo',
        file,
        true,
        false,
        false,
        true
      );
    }
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
          duration: 10000,
        },
      });
    }
  }
}

export function* changeDriverShift(data: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    let response;
    if (data.request === START_SHIFT) {
      response = yield call(
        API,
        HTTP_POST,
        DRIVER_CHECK_IN(data.driverPayload.driverInfo.uid),
        data.driverPayload,
        true
      );
    } else {
      response = yield call(
        API,
        HTTP_POST,
        DRIVER_CHECK_OUT(data.driverPayload.driverInfo.uid),
        {},
        true
      );
    }
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      const driverName = [];
      if (data.driverPayload.driverInfo.firstName)
        driverName.push(data.driverPayload.driverInfo.firstName);
      if (data.driverPayload.driverInfo.lastName)
        driverName.push(data.driverPayload.driverInfo.lastName);
      if (data.request === START_SHIFT) {
        AppEvents.trackEvent(
          Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Actions.DRIVER_START_SHIFT.id,
          Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Actions.DRIVER_START_SHIFT.name,
          null,
          { uid: data.driverPayload.driverInfo.uid, name: driverName.join(' ') }
        );
        if (data.vehiclePayload?.truckUid) {
          yield call(changeVehicleShift, {
            request: data.request,
            data: data.vehiclePayload,
          });
        }
      } else if (data.request === END_SHIFT) {
        AppEvents.trackEvent(
          Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Actions.DRIVER_END_SHIFT.id,
          Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Actions.DRIVER_END_SHIFT.name,
          null,
          { uid: data.driverPayload.driverInfo.uid, name: driverName.join(' ') }
        );
        if (data.vehiclePayload?.truckUid) {
          yield call(changeVehicleShift, {
            request: data.request,
            data: data.vehiclePayload,
          });
        }
      }
      yield put({
        type: CHANGE_DRIVER_SHIFT_SUCCESS,
        data: data,
      });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* changeVehicleShift(data: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    let response;
    if (data.request === START_SHIFT) {
      response = yield call(
        API,
        HTTP_POST,
        VEHICLE_CHECK_IN(data.data.truckUid),
        data.data.payload,
        true
      );
    } else {
      response = yield call(
        API,
        HTTP_POST,
        VEHICLE_CHECK_OUT(data.data.truckUid),
        data.data.payload,
        true
      );
    }
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      if (data.request === START_SHIFT) {
        AppEvents.trackEvent(
          Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Actions.VEHICLE_START_SHIFT.id,
          Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Actions.VEHICLE_START_SHIFT
            .name,
          null,
          { uid: data.data.truckUid, name: data.data.vehicleName }
        );
      } else {
        AppEvents.trackEvent(
          Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Actions.VEHICLE_END_SHIFT.id,
          Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Actions.VEHICLE_END_SHIFT.name,
          null,
          { uid: data.data.truckUid, name: data.data.vehicleName }
        );
      }

      yield put({
        type: CHANGE_VEHICLE_SHIFT_SUCCESS,
        data: data,
      });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
      yield call(vehicleManagementHandler, error.response.data);
    }
  }
}

export function* linkDriverVehicle({ data }: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const provider = yield select(getAuthData);
    const payload = {
      driverInfo: {
        companyId: provider.providerId,
        externalId: data.externalId,
      },
    };
    const response = yield call(
      API,
      HTTP_PUT,
      LINK_TRUCK_DRIVER(data.driverUid, data.vehicleUid),
      payload,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      let successMessage = '';
      if (data.assignType === 'VEHICLE') {
        successMessage = 'Changes Saved! The driver has been updated.';
        yield call(getDriverList);
        AppEvents.trackEvent(
          Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages.ON_ASSIGN_VEHICLE_MODAL
            .Actions.VEHICLE_ASSIGNED_TO_DRIVER.id,
          Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Pages.ON_ASSIGN_VEHICLE_MODAL
            .Actions.VEHICLE_ASSIGNED_TO_DRIVER.name,
          null,
          {
            driver: { uid: data.driverUid, name: data?.driverName?.join(' ') },
            vehicle: { uid: data.vehicleUid, name: data?.vehicleName },
          }
        );
      } else if (data.assignType === 'DRIVER') {
        successMessage = 'Changes Saved! The vehicle has been updated.';
        yield call(getVehicleList);
        AppEvents.trackEvent(
          Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages.ON_ASSIGN_DRIVER_MODAL
            .Actions.DRIVER_ASSIGNED_TO_VEHICLE.id,
          Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.Pages.ON_ASSIGN_DRIVER_MODAL
            .Actions.DRIVER_ASSIGNED_TO_VEHICLE.name,
          null,
          {
            driver: { uid: data.driverUid, name: data?.driverName?.join(' ') },
            vehicle: { uid: data.vehicleUid, name: data?.vehicleName },
          }
        );
      }
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'success',
          text: successMessage,
        },
      });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.error,
        },
      });
    }
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* sendBgCheckInvitation({ data }: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const providerData = yield select(getAuthData);
    const formData = {
      first_name: data.driverInfo.firstName,
      last_name: data.driverInfo.lastName,
      phonenumber: data.driverInfo.phone,
      provideruid: providerData.providerUid,
      driveruid: data.driverInfo.uid,
    };
    const response = yield call(
      API,
      HTTP_POST,
      BG_CHECK_INVITE,
      formData,
      true,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data?.data?.status) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'success',
          text: `Success!  We’ve texted ${formData.first_name} ${formData.last_name} a link to begin the background check process.`,
        },
      });

      AppEvents.trackEvent(
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Actions.BG_CHECK_REQUESTED.id,
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Actions.BG_CHECK_REQUESTED.name,
        null,
        {
          id: response.data.data?.id,
          checkrId: response.data.data?.checkrId,
          driver_uid: response.data.data?.driver_uid,
          first_name: formData.first_name,
          last_name: formData.last_name,
          status: response.data.data?.status,
          tiny_url: response.data.data?.tiny_url,
        }
      );

      if (data.index !== undefined) {
        const driverBGCheckStatusResp = response.data.data;
        const driverList: DriverDTO[] = (yield select(getFleetData)).driverList;
        const bgStatusObj = bgStatusList().find((bgStatus: any) => {
          if (driverBGCheckStatusResp?.adjudication) {
            return (
              bgStatus.key.toLowerCase() ===
              driverBGCheckStatusResp.adjudication.toLowerCase()
            );
          } else {
            return (
              bgStatus.key.toLowerCase() ===
              driverBGCheckStatusResp.status.toLowerCase()
            );
          }
        });
        if (bgStatusObj) {
          driverList[data.index].bgCheck = {
            status: bgStatusObj.value,
            color: bgStatusObj.color,
            bgReqBtnVisible: bgStatusObj.bgReqBtn,
            amountOwed: driverBGCheckStatusResp.price,
            email: driverBGCheckStatusResp.email,
          };
        }
        yield put({
          type: GET_FLEET_DRIVER_LIST_SUCCESS,
          response: [...driverList],
        });
      }
    } else {
      const errorResponseDTO: any = response?.data?.errorResponseDTO;
      if (errorResponseDTO?.message) {
        const params = {
          driverUid: formData.driveruid,
          firstName: formData.first_name,
          lastName: formData.last_name,
          code: errorResponseDTO.code,
          serviceName: errorResponseDTO.serviceName,
          message: errorResponseDTO.message,
          status: errorResponseDTO.status,
        };
        AppEvents.trackEvent(
          Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Actions.BG_CHECK_REQUEST_FAILED
            .id,
          Events.ON_FLEET_ASSETS_DRIVER_SCREEN.Actions.BG_CHECK_REQUEST_FAILED
            .name,
          null,
          params
        );
        yield put({
          type: SHOW_NOTIFICATION,
          payload: {
            type: 'error',
            text: errorResponseDTO.message,
          },
        });
      }
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error && error.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.error,
        },
      });
    }
    if (error && error.response?.data?.error) {
      yield put({
        type: SHOW_NOTIFICATION,
        payload: {
          type: 'error',
          text: error.response.data.error,
        },
      });
    }
  }
}

export function* vehicleManagementHandler({ error }: any): any {
  try {
    if (error === 'Vehicle management is disabled for this provider') {
      console.log('Vehicle management is disabled ---');
      yield put({
        type: UPDATE_VEHICLE_MANAGEMENT_FLAG,
        providerPreference: { vehicleManagementEnabled: false },
      });
    }
  } catch (error) {
    console.error(error.toString());
  }
}

function* fleetWatcher(): any {
  yield takeLatest(GET_TRUCK_TYPES, getTruckTypes);
  yield takeLatest(GET_FLEET_DRIVER_LIST, getDriverList);
  yield takeLatest(GET_DRIVERS_BG_CHECK_LIST, getDriversBGStatusList);
  yield takeLatest(GET_FLEET_VEHICLE_LIST, getVehicleList);
  yield takeLatest(UPDATE_DRIVER, updateDriver);
  yield takeLatest(ADD_DRIVER, addDriver);
  yield takeLatest(DELETE_DRIVER, deleteDriver);
  yield takeLatest(DELETE_VEHICLE, deleteVehicle);
  yield takeLatest(ADD_VEHICLE, addVehicle);
  yield takeLatest(UPDATE_VEHICLE, updateVehicle);
  yield takeLatest(REGENERATE_ACTIVATION_CODE, regenerateActivationCode);
  yield takeLatest(SEND_TEXT_LINK, sendTextLink);
  yield takeLatest(GET_SERVICE_CATEGORIES, getServiceCategories);
  yield takeLatest(CHANGE_DRIVER_SHIFT, changeDriverShift);
  yield takeLatest(CHANGE_VEHICLE_SHIFT, changeVehicleShift);
  yield takeLatest(LINK_DRIVER_VEHICLE, linkDriverVehicle);
  yield takeLatest(SEND_BG_CHECK_INVITATION, sendBgCheckInvitation);
  yield takeLatest(BUSINESS_INFO_REQUEST, businessInfoRequest);
}

export default fleetWatcher;
