import React from 'react';
import { Button as Btn } from 'react-bootstrap';
import './button.scss';

export interface Props {
  variant: 'basic' | 'primary' | 'secondary' | 'arrow' | 'dark';
  clx?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'submit' | 'reset';
  'data-testid'?: string;
  children: any;
}

export const Button: React.FC<Props> = (props: Props): any => {
  const { variant, clx, onClick, disabled, type, children } = props;
  const dataTestid = props['data-testid'];
  const getProps = React.useMemo(() => {
    const props: any = { disabled };
    let className = '';
    switch (variant) {
      case 'primary':
        className = 'button_primary_blue';
        break;
      case 'secondary':
        className = 'button_secondary';
        break;
      case 'arrow':
        className = 'arrow_button';
        break;
      default:
        className = '';
    }
    props.className = `${className} ${clx ?? ''}`;
    if (type) {
      props.type = type;
    } else {
      props.onClick = onClick;
    }
    if (dataTestid) {
      props['data-testid'] = dataTestid;
    }
    return props;
  }, [variant, clx, onClick, disabled, type]);

  return <Btn {...getProps}>{children}</Btn>;
};
