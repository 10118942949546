import { rootReducer } from '../reducers/combineReducer';
import { InitialAppStateTypes } from '../types/reducerTypes';
import { applyMiddleware, createStore, Store } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { uncaughtErrorsHandler } from './uncaughtErrorsHandler';
import rootSaga from '../saga/rootSaga';
import { printLog } from '../utils/logging';

let store: Store<InitialAppStateTypes>;
export default function configureStore(
  initialState?: any
): Store<InitialAppStateTypes> {
  const logger = createLogger();
  const sagaMiddleware = createSagaMiddleware({
    onError: uncaughtErrorsHandler,
  });
  let middlewares;
  printLog(process.env);
  if (process.env.NODE_ENV === 'development') {
    middlewares = applyMiddleware(sagaMiddleware, logger); // Create Store
  } else {
    middlewares = applyMiddleware(sagaMiddleware); // Create Store
  }

  // store = createStore(rootReducer, initialState, middlewares);
  store = createStore(rootReducer, middlewares);
  sagaMiddleware.run(rootSaga);
  return store;
}
export function getStore(): Store<InitialAppStateTypes> {
  return store;
}

export function createTestStore(
  initialState?: any
): Store<InitialAppStateTypes> {
  store = createStore(rootReducer, initialState);
  return store;
}
