export function uncaughtErrorsHandler(error: any, errorInfo: any): void {
  // eslint-disable-next-line no-console
  console.log(
    `%c UNCAUGHT SAGA ERROR: ${JSON.stringify(errorInfo, null, 2)}`,
    style
  );

  //NOTE: apply error logging logic below
}

const style = [
  'background: #fffbdb',
  'color: #f22000',
  'border-left: 1px solid #f22000',
  'display: block',
  'font-weight: 400',
  'padding-left: 12px',
].join(';');
