import React from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getAuthData } from '../../saga/login/loginSelectors';
import './fleetAssets.scss';
import TechniciansContainer from '../../containers/techniciansContainer';
import VehiclesContainer from '../../containers/vehiclesContainer';
import * as fleetAction from '../../saga/fleet/fleetActionTypes';
import {
  EventName,
  Events as PostMsgEvents,
  sendMessage,
  postMessage,
  updateVehicleManagementPostMessage,
} from '../../utils/eventHandler';
import { ANGULAR_AUTH_SUCCESS } from '../../saga/login/loginActionTypes';
import { printLog } from '../../utils/logging';
import { AuthTypes } from '../../types/reducerTypes';
import { config } from '../../utils/config';
import { BETA_IMG } from '../../utils/images';
import { AppEvents } from '../../constants/analytics';
import { Events } from '../../constants/appEvents';

enum FLEET_ASSETS {
  DRIVERS = 'drivers',
  VEHICLES = 'vehicles',
}

const FleetAssets: React.FC = () => {
  const [key, setKey] = React.useState(FLEET_ASSETS.DRIVERS);
  const dispatch = useDispatch();
  const authData: AuthTypes = useSelector(getAuthData, shallowEqual);

  const fetchFleetHelperData = React.useCallback(() => {
    dispatch({ type: fleetAction.GET_TRUCK_TYPES });
    dispatch({ type: fleetAction.GET_SERVICE_CATEGORIES });
    dispatch({ type: fleetAction.BUSINESS_INFO_REQUEST });
  }, [dispatch]);

  const fetchTechnicians = React.useCallback(() => {
    dispatch({ type: fleetAction.GET_FLEET_DRIVER_LIST });
  }, [dispatch]);

  const fetchVehicles = React.useCallback(() => {
    dispatch({ type: fleetAction.GET_FLEET_VEHICLE_LIST });
  }, [dispatch]);

  React.useEffect(() => {
    if (authData.authToken) {
      // fetchTechnicians(); // TODO - remove
      setTimeout(() => {
        fetchVehicles();
        fetchFleetHelperData();
        AppEvents.setTrackingConfig(); // TODO - uncomment to enable micro-app level GA config
      }, 800);
    }
  }, [
    fetchFleetHelperData,
    fetchTechnicians,
    fetchVehicles,
    authData.authToken,
  ]);

  React.useEffect(() => {
    PostMsgEvents.on(EventName.ANGULAR_INIT_DATA, function (params: any): void {
      try {
        if (params) {
          dispatch({ type: ANGULAR_AUTH_SUCCESS, params });
        }
      } catch (error) {
        printLog(error);
      }
    });
    sendMessage(postMessage);
  }, []);

  React.useEffect(() => {
    if (key === FLEET_ASSETS.DRIVERS) {
      if (authData.authToken) fetchTechnicians();
      AppEvents.trackPage(
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.id,
        Events.ON_FLEET_ASSETS_DRIVER_SCREEN.name
      );
    } else if (key === FLEET_ASSETS.VEHICLES) {
      if (authData.authToken) fetchVehicles();
      AppEvents.trackPage(
        Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.id,
        Events.ON_FLEET_ASSETS_VEHICLE_SCREEN.name
      );
    }
  }, [authData.authToken, key]);

  React.useEffect(() => {
    if (key === FLEET_ASSETS.VEHICLES) {
      setKey(FLEET_ASSETS.DRIVERS);
      sendMessage(updateVehicleManagementPostMessage);
    }
  }, [authData.configs?.providerPreference?.vehicleManagementEnabled]);

  return (
    <>
      {authData.authToken && (
        <Container fluid>
          <Row>
            <Col sm={12} className="content_area pr0 pt0 pb0 pl0">
              <Col className="pt15">
                <p className="settings_main_title">Fleet Assets</p>
                {/* <img className="beta-img" alt="beta" src={BETA_IMG} /> */}
              </Col>
              <Tabs
                id="fleets-tabs"
                activeKey={key}
                onSelect={(k: any) => setKey(k)}
                className="settings_tabs"
                data-testid="fleets-tabs"
              >
                <Tab
                  eventKey={FLEET_ASSETS.DRIVERS}
                  title="Drivers"
                  data-testid="drivers"
                >
                  <TechniciansContainer />
                </Tab>

                {config.isVehicleManagementEnabled() && (
                  <Tab
                    eventKey={FLEET_ASSETS.VEHICLES}
                    title="Vehicles"
                    data-testid="vehicles"
                  >
                    <VehiclesContainer />
                  </Tab>
                )}
              </Tabs>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default FleetAssets;
