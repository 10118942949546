import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Toast } from 'react-bootstrap';

import { NotificationDinamicTypes } from '../types/reducerTypes';
import { getNotifications } from '../saga/notifications/notificationsSelectors';
import { FORCE_HIDE_NOTIFICATION } from '../saga/notifications/notificationsActionTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faInfoCircle,
  faExclamation,
  faCircle,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

import styles from './notificationsContainer.module.scss';
import { createMarkup } from '../utils/commonUtility';

function Notifications(): React.ReactElement {
  const { dinamic: notifications, static: staticNotifications } = useSelector(
    getNotifications,
    shallowEqual
  );
  const dispatch = useDispatch();

  const removeToast = React.useCallback(
    (id: number): void => {
      dispatch({ type: FORCE_HIDE_NOTIFICATION, payload: id });
    },
    [dispatch]
  );

  return (
    <>
      {staticNotifications?.length > 0 && (
        <div className={styles.staticContainer}>
          {staticNotifications.map((n: any, idx: number) => (
            <p key={idx}>{n}</p>
          ))}
        </div>
      )}
      <div className={styles.toastWrapper}>
        {notifications.length > 0 &&
          notifications.map((n: NotificationDinamicTypes) => {
            let icon: any;
            switch (n.type) {
              case 'error':
                icon = faExclamationTriangle;
                break;
              case 'warning':
                icon = faExclamation;
                break;
              case 'info':
                icon = faInfoCircle;
                break;
              case 'success':
                icon = faCheck;
                break;
              default:
                icon = faCircle;
            }
            return (
              <Toast
                key={n.id}
                className={styles[`toast_${n.type}`]}
                show={!!notifications.find((notif) => notif.id === n.id)}
                onClose={() => removeToast(n.id)}
                autohide
                delay={5000}
              >
                {/* <Toast.Header bsPrefix={styles.toastHeader} /> */}
                <Toast.Body className={styles.toastBody}>
                  <FontAwesomeIcon icon={icon} />
                  <div dangerouslySetInnerHTML={createMarkup(n.text)} />
                </Toast.Body>
              </Toast>
            );
          })}
      </div>
    </>
  );
}

export default Notifications;
