import { createSelector } from 'reselect';
import {
  InitialAppStateTypes,
  AuthInitialStateTypes,
} from '../../types/reducerTypes';

export const getAuthentication = (
  state: InitialAppStateTypes
): AuthInitialStateTypes => state.authentication;

export const getAuthData = createSelector(
  getAuthentication,
  (auth) => auth.authData
);
