import {
  BusinessInfo,
  DriverDTO,
  DriverInfo,
  FleetAssetsTypes,
  VehicleDTO,
} from '../../types/reducerTypes';
import {
  BUSINESS_INFO_SUCCESS,
  CHANGE_DRIVER_SHIFT_PUBNUB,
  CHANGE_DRIVER_SHIFT_SUCCESS,
  CHANGE_VEHICLE_SHIFT_PUBNUB,
  CHANGE_VEHICLE_SHIFT_SUCCESS,
  GET_FLEET_DRIVER_LIST_SUCCESS,
  GET_FLEET_VEHICLE_LIST_SUCCESS,
  GET_SERVICE_CATEGORIES_SUCCESS,
  GET_TRUCK_TYPES_SUCCESS,
  REGENERATE_ACTIVATION_CODE_SUCCESS,
  START_SHIFT,
  UPDATE_SERVICE_CATEGORIES,
  UPDATE_VEHICLE_DTO,
} from './fleetActionTypes';
import { printLog } from '../../utils/logging';

export const techniciansData: FleetAssetsTypes = {
  truckTypes: [],
  driverList: [],
  vehicleList: [],
  categories: [],
  businessInfo: {} as BusinessInfo,
};

export const fleetReducer = (
  state: FleetAssetsTypes = techniciansData,
  action: any
): object => {
  switch (action.type) {
    case GET_TRUCK_TYPES_SUCCESS:
      return {
        ...state,
        truckTypes: action.response.data,
      };
    case GET_FLEET_DRIVER_LIST_SUCCESS:
      return {
        ...state,
        driverList: action.response,
      };
    case GET_FLEET_VEHICLE_LIST_SUCCESS: {
      // console.error('GET_FLEET_VEHICLE_LIST SUCCESS');
      // console.error(action.response.data[0]?.truckInfo?.services);
      return {
        ...state,
        vehicleList: action.response,
      };
    }
    case GET_SERVICE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.response,
      };
    case BUSINESS_INFO_SUCCESS:
      return {
        ...state,
        businessInfo: action.response,
      };
    case REGENERATE_ACTIVATION_CODE_SUCCESS: {
      const driverList: DriverDTO[] = [...state.driverList];
      for (const idx in driverList) {
        if (driverList[idx].uid === action.response?.entityUid) {
          driverList[idx].appInfo.activationCode =
            action.response?.activationCode;
          break;
        }
      }
      return {
        ...state,
        driverList,
      };
    }
    case CHANGE_DRIVER_SHIFT_SUCCESS: {
      const driverList: DriverDTO[] = [...state.driverList];
      for (const idx in driverList) {
        if (
          driverList[idx].uid === action.data.driverPayload?.driverInfo?.uid
        ) {
          if (action.data.request === START_SHIFT) {
            driverList[idx].status = 1;
            driverList[idx].lastVehicleLocation =
              action.data.driverPayload.location;
          } else {
            driverList[idx].status = 0;
          }
          break;
        }
      }
      return {
        ...state,
        driverList,
      };
    }
    case CHANGE_VEHICLE_SHIFT_SUCCESS: {
      const vehicleList: VehicleDTO[] = [...state.vehicleList];
      for (const idx in vehicleList) {
        if (vehicleList[idx].truckInfo.uid === action.data.data.truckUid) {
          if (action.data.request === START_SHIFT) {
            vehicleList[idx].truckInfo.status = 1;
            vehicleList[idx].location = action.data.data.location;
          } else {
            vehicleList[idx].truckInfo.status = 0;
            vehicleList[idx].driverInfo = {} as DriverInfo;
          }
          break;
        }
      }
      return {
        ...state,
        vehicleList,
      };
    }
    case CHANGE_DRIVER_SHIFT_PUBNUB:
      return {
        ...state,
        driverList: action.data,
      };
    case CHANGE_VEHICLE_SHIFT_PUBNUB:
      return {
        ...state,
        vehicleList: action.data,
      };
    case UPDATE_VEHICLE_DTO: {
      const vehicleList: VehicleDTO[] = [...state.vehicleList];
      for (const idx in vehicleList) {
        if (vehicleList[idx].truckInfo.uid === action.data.truckInfo.uid) {
          /*vehicleList[idx].truckInfo.externalId = action.data.truckInfo.externalId;
          vehicleList[idx].truckInfo.licensePlate = action.data.truckInfo.licensePlate;
          vehicleList[idx].truckInfo.name = action.data.truckInfo.name;
          vehicleList[idx].truckInfo.truckTypeId = action.data.truckInfo.truckTypeId;*/
          vehicleList[idx].truckInfo.services = action.data.payload.services;
          break;
        }
      }
      return {
        ...state,
        vehicleList,
      };
    }
    default:
      return state;
  }
};
